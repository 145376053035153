import React, { useEffect, useRef, useState } from 'react';
import { createChart, LineStyle } from 'lightweight-charts';
import { useDispatch, useSelector } from "react-redux";
// import axios from 'axios';

import './index.css';

const API_BASE_URL = "http://172.18.1.81:3100/money_in_out";

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const ChartSectionWithMultipleLines = ({ title, market, type }) => {
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);
  const lineSeriesRef = useRef(new Map());
  const [tableData, setTableData] = useState([]);
  const tableDataRef = useRef([]);
  const [previousRanks, setPreviousRanks] = useState({});
  const previousRanksRef = useRef(previousRanks);
  const [lastRankChange, setLastRankChange] = useState({});
  const lastRankChangeRef = useRef(lastRankChange);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // Keep track of the currently highlighted series
  const highlightedSeriesRef = useRef(null);
  
  // New states and refs for modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const fullScreenChartContainerRef = useRef(null);
  const fullScreenChartRef = useRef(null);

  const fullScreenSeriesRef = useRef(new Map());
  const highlightedModalSeriesRef = useRef(null);

  // New ref to store series data
  const seriesDataRef = useRef(new Map());

  const isMounted = useRef(true);

  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [selectedModalSymbol, setSelectedModalSymbol] = useState(null);
  const originalColorsRef = useRef(new Map());
  const earthToneColor = '#D2B48C'; // Tan color

  const priceLinesRef = useRef(new Map());
  const fullScreenPriceLinesRef = useRef(new Map());

  
  const csrfToken = useSelector((state) => state.csrfToken);
  
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    previousRanksRef.current = previousRanks;
  }, [previousRanks]);

  useEffect(() => {
    lastRankChangeRef.current = lastRankChange;
  }, [lastRankChange]);

  useEffect(() => {
    tableDataRef.current = tableData;
  }, [tableData]);

  const getTodayDateString = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const adjustToLocalTime = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const timezoneOffset = date.getTimezoneOffset() * 60;
    return timestamp - timezoneOffset;
  };

  // Add this function to handle full screen chart update
  const updateFullScreenChart = () => {
    if (!isModalOpen || !fullScreenChartRef.current) return;

    // Clear existing series in full screen chart
    fullScreenSeriesRef.current.forEach((series) => {
      fullScreenChartRef.current.removeSeries(series);
    });
    fullScreenSeriesRef.current.clear();
    fullScreenPriceLinesRef.current.clear();

    // Re-add series from seriesDataRef
    seriesDataRef.current.forEach((data, symbol) => {
      // Find original series to copy options (color)
      const originalSeries = lineSeriesRef.current.get(symbol);
      if (!originalSeries) return;
      const uniqueSortedData = Array.from(
        new Map(data.map((item) => [item.time, item])).values()
      ).sort((a, b) => a.time - b.time);

      const newSeries = fullScreenChartRef.current.addLineSeries({
        color: originalSeries.options().color,
        lineWidth: originalSeries.options().lineWidth,
        visible: originalSeries.options().visible,
        lastValueVisible: false,
        priceLineVisible: false
      });
      newSeries.setData(uniqueSortedData);
      fullScreenSeriesRef.current.set(symbol, newSeries);

      const lastDataPoint = uniqueSortedData[uniqueSortedData.length - 1];
      if (lastDataPoint) {
        const priceLine = newSeries.createPriceLine({
          price: lastDataPoint.value,
          color: newSeries.options().color,
          lineWidth: 1,
          lineStyle: LineStyle.Solid,
          axisLabelVisible: true,
          title: symbol,
          lineVisible: false
        });
        fullScreenPriceLinesRef.current.set(symbol, priceLine);
      }
    });
  };

  const fetchHistoricalData = async () => {
    try {
      // const response = await axios.get(`http://172.18.1.81:3100/money_in_out/today/${market}`, {
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      // });
      const fetch_response = await fetch(`${window.config.base_api_url}/money_in_out/today/${market}`, {
        headers: {
          'Content-Type': 'application/json',
          'xsrf-token': csrfToken
        },
      });

      const response = await fetch_response.json();
      console.log(response)

      const data = response.data[type]; // 'in' or 'out'
      const historicalData = {};
      const todayDateString = getTodayDateString();


      // Get the latest time key
      const times = Object.keys(data.rank);
      times.sort(); // Assuming times are in ISO format and will sort correctly
      const latestTime = times[times.length - 1];

      const latestSymbols = Object.keys(data.rank[latestTime]);

      // Build historical data
      times.forEach((time) => {
        Object.keys(data.rank[time]).forEach((symbol) => {
          if (!historicalData[symbol]) {
            historicalData[symbol] = [];
          }
          const dateTimeString = `${todayDateString}T${time}`;
          const timestamp = Math.floor(new Date(dateTimeString).getTime() / 1000);

          historicalData[symbol].push({
            time: adjustToLocalTime(timestamp),
            rank: data.rank[time][symbol],
            value: data.value[time][symbol],
          });
        });
      });

      // Initialize previousRanks and lastRankChange from historicalData
      const newPreviousRanks = {};
      const newLastRankChange = {};

      // Update chart series data and store data for symbols in latestSymbols
      latestSymbols.forEach((symbol) => {
        const symbolData = historicalData[symbol];

        if (!symbolData || symbolData.length === 0) {
          newPreviousRanks[symbol] = null;
          newLastRankChange[symbol] = null;
          return;
        }

        symbolData.sort((a, b) => a.time - b.time);

        // Find the last rank change before the latest data point
        let lastRankChangeRank = symbolData[0].rank;
        for (let i = 1; i < symbolData.length; i++) {
          if (symbolData[i].rank !== symbolData[i - 1].rank) {
            lastRankChangeRank = symbolData[i - 1].rank;
          }
        }

        const latestRank = symbolData[symbolData.length - 1].rank;

        newPreviousRanks[symbol] = latestRank;
        newLastRankChange[symbol] = lastRankChangeRank;

        // Create or get existing series
        let series = lineSeriesRef.current.get(symbol);
        if (!series) {
          const color = getRandomColor();
          series = chartRef.current.addLineSeries({
            color: color,
            lineWidth: 2,
            lastValueVisible: false, // Disable the default last value label
            priceLineVisible: false
          });
          lineSeriesRef.current.set(symbol, series);
          originalColorsRef.current.set(symbol, color); // Store original color
        }

        const seriesData = symbolData.map((dataPoint) => ({
          time: dataPoint.time,
          value: dataPoint.value,
        }));
        series.setData(seriesData);
        seriesDataRef.current.set(symbol, seriesData); // Store data

        // Create or update price line
        const lastDataPoint = seriesData[seriesData.length - 1];
        if (lastDataPoint) {
          // Remove existing price line if it exists
          if (priceLinesRef.current.has(symbol)) {
            const oldPriceLine = priceLinesRef.current.get(symbol);
            series.removePriceLine(oldPriceLine);
          }

          const priceLine = series.createPriceLine({
            price: lastDataPoint.value,
            color: series.options().color,
            lineWidth: 1,
            lineStyle: LineStyle.Solid,
            axisLabelVisible: true,
            title: symbol,
            lineVisible: false, // Add this property to hide the horizontal line
          });
          priceLinesRef.current.set(symbol, priceLine);
        }
      });

      setPreviousRanks(newPreviousRanks);
      setLastRankChange(newLastRankChange);

      // Remove symbols not in latestSymbols from lineSeriesRef, seriesDataRef, and chart
      const allSymbols = Array.from(lineSeriesRef.current.keys());
      const symbolsToRemove = allSymbols.filter(
        (symbol) => !latestSymbols.includes(symbol)
      );

      symbolsToRemove.forEach((symbol) => {
        const series = lineSeriesRef.current.get(symbol);
        if (series) {
          // Remove price line
          const priceLine = priceLinesRef.current.get(symbol);
          if (priceLine) {
            series.removePriceLine(priceLine);
            priceLinesRef.current.delete(symbol);
          }
          chartRef.current.removeSeries(series);
        }
        lineSeriesRef.current.delete(symbol);
        seriesDataRef.current.delete(symbol);
        originalColorsRef.current.delete(symbol);
      });


      // Reset selection if the selected symbol is no longer in the latest data
      if (selectedSymbol && !latestSymbols.includes(selectedSymbol)) {
        setSelectedSymbol(null);
      }

      // Update chart series data and store data for symbols in latestSymbols
      latestSymbols.forEach((symbol) => {
        if (!lineSeriesRef.current.has(symbol)) {
          const color = getRandomColor();
          lineSeriesRef.current.set(
            symbol,
            chartRef.current.addLineSeries({
              color: color,
              lineWidth: 2,
            })
          );
          originalColorsRef.current.set(symbol, color); // Store original color
        }
        const seriesData = historicalData[symbol].map((dataPoint) => ({
          time: dataPoint.time,
          value: dataPoint.value,
        }));
        lineSeriesRef.current.get(symbol).setData(seriesData);
        seriesDataRef.current.set(symbol, seriesData); // Store data
      });

      // Prepare table data with background color based on rank change
      const updatedTableData = latestSymbols.map((symbol) => {
        const symbolData = historicalData[symbol];
        const latestData = symbolData[symbolData.length - 1];
        const latestRank = latestData.rank;
        const lastChangeRank = newLastRankChange[symbol];

        let color = 'transparent';
        if (lastChangeRank != null) {
          if (latestRank < lastChangeRank) {
            color = '#ccffcc'; // Light green
          } else if (latestRank > lastChangeRank) {
            color = '#ffcccc'; // Light red
          }
        }

        return {
          rank: latestRank,
          symbol,
          value: latestData.value,
          color,
        };
      });

      setTableData(updatedTableData);

      if (isModalOpen) {
        updateFullScreenChart();
      }

    } catch (error) {
      console.error(`Error fetching historical data for ${market}:`, error);
    }
  };

  const fetchCurrentData = async () => {
    try {
      if (Object.keys(previousRanksRef.current).length === 0) {
        console.warn('Previous ranks not initialized, skipping rank comparison.');
        return;
      }
  
      // const response = await axios.get(
      //   `http://172.18.1.81:3100/money_in_out/now/${market}`,
      //   {
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //   }
      // );

      const fetch_response = await fetch(`${window.config.base_api_url}/money_in_out/now/${market}`, {
        headers: {
          'Content-Type': 'application/json',
          'xsrf-token': csrfToken
        },
      });
      const response = await fetch_response.json();

  
      const data = response.data[type]; // 'in' or 'out'
      const timeKey = Object.keys(data.rank)[0];
      const todayDateString = getTodayDateString();
      const dateTimeString = `${todayDateString}T${timeKey}`;
      const timestamp = Math.floor(new Date(dateTimeString).getTime() / 1000);
  
      const latestSymbols = Object.keys(data.rank[timeKey]);
  
      const updatedTableData = latestSymbols.map((symbol) => {
        const currentRank = data.rank[timeKey][symbol];
        const lastChangeRank = lastRankChangeRef.current[symbol] || currentRank;
  
        // Get previous color
        const previousEntry = tableDataRef.current.find(
          (entry) => entry.symbol === symbol
        );
        const previousColor = previousEntry ? previousEntry.color : 'transparent';
  
        let color = previousColor;
  
        if (currentRank === lastChangeRank) {
          // Rank has returned to the value at which the color last changed
          color = 'transparent';
          // Update lastRankChange to the current rank
          lastRankChangeRef.current = {
            ...lastRankChangeRef.current,
            [symbol]: currentRank,
          };
        } else {
          // Rank has changed from lastChangeRank
          if (currentRank < lastChangeRank) {
            color = '#ccffcc'; // Light green
          } else if (currentRank > lastChangeRank) {
            color = '#ffcccc'; // Light red
          }
          // Do not update lastRankChange here
        }
  
        return {
          rank: currentRank,
          symbol,
          time: timeKey,
          value: data.value[timeKey][symbol],
          color,
        };
      });
  
      setTableData(updatedTableData);
  
      // Update previousRanks
      const newPreviousRanks = {};
      updatedTableData.forEach((row) => {
        newPreviousRanks[row.symbol] = row.rank;
      });
      setPreviousRanks(newPreviousRanks);
      previousRanksRef.current = newPreviousRanks;
  
      // Update lastRankChangeRef.current to only include latest symbols
      const newLastRankChange = {};
      latestSymbols.forEach((symbol) => {
        if (lastRankChangeRef.current.hasOwnProperty(symbol)) {
          newLastRankChange[symbol] = lastRankChangeRef.current[symbol];
        }
      });
      setLastRankChange(newLastRankChange);
      lastRankChangeRef.current = newLastRankChange;
  
      // Remove symbols not in latestSymbols from lineSeriesRef, seriesDataRef, and chart
      const allSymbols = Array.from(lineSeriesRef.current.keys());
      const symbolsToRemove = allSymbols.filter(
        (symbol) => !latestSymbols.includes(symbol)
      );
  
      symbolsToRemove.forEach((symbol) => {
        // Remove line series from chart
        const series = lineSeriesRef.current.get(symbol);
        if (series) {
          chartRef.current.removeSeries(series);
        }
        lineSeriesRef.current.delete(symbol);
        seriesDataRef.current.delete(symbol);
        originalColorsRef.current.delete(symbol);
      });
  
      // Reset selection if the selected symbol is no longer in the latest data
      if (selectedSymbol && !latestSymbols.includes(selectedSymbol)) {
        setSelectedSymbol(null);
      }
  
      // Update chart series and stored data for symbols in latestSymbols
      latestSymbols.forEach((symbol) => {
        const updatePoint = {
          time: adjustToLocalTime(timestamp),
          value: data.value[timeKey][symbol],
        };
      
        let series = lineSeriesRef.current.get(symbol);
        if (series) {
          // Update existing series
          series.update(updatePoint);
  
          // Update stored data
          const existingData = seriesDataRef.current.get(symbol) || [];
  
          if (
            existingData.length > 0 &&
            existingData[existingData.length - 1].time === updatePoint.time
          ) {
            existingData[existingData.length - 1] = updatePoint;
          } else {
            existingData.push(updatePoint);
          }
  
          seriesDataRef.current.set(symbol, existingData);
           // Update price line
          if (priceLinesRef.current.has(symbol)) {
            const oldPriceLine = priceLinesRef.current.get(symbol);
            series.removePriceLine(oldPriceLine);
          }

          const newPriceLine = series.createPriceLine({
            price: updatePoint.value,
            color: series.options().color,
            lineWidth: 1,
            lineStyle: LineStyle.Solid,
            axisLabelVisible: true,
            title: symbol,
            lineVisible: false // Add this
          });

          priceLinesRef.current.set(symbol, newPriceLine);
        } else {
          // Create new series
          const color = getRandomColor();
          series = chartRef.current.addLineSeries({
            color,
            lineWidth: 2,
            lastValueVisible: false, // Disable the default last value label
            priceLineVisible: false
          });
          series.setData([updatePoint]);
          lineSeriesRef.current.set(symbol, series);
          originalColorsRef.current.set(symbol, color);
          seriesDataRef.current.set(symbol, [updatePoint]);

          // Create price line
          const priceLine = series.createPriceLine({
            price: updatePoint.value,
            color: series.options().color,
            lineWidth: 1,
            lineStyle: LineStyle.Solid,
            axisLabelVisible: true,
            title: symbol,
            lineVisible: false, // Hide the horizontal line
          });
          priceLinesRef.current.set(symbol, priceLine);
        }
      });

      if (isModalOpen) {
        updateFullScreenChart();
      }

    } catch (error) {
      console.error(`Error fetching current data for ${market}:`, error);
    }
  };
  
  // Also, in the useEffect that runs when isModalOpen changes, if data is already there, call updateFullScreenChart:
  useEffect(() => {
    if (isModalOpen && fullScreenChartRef.current) {
      // If data arrived before, we can immediately update
      updateFullScreenChart();
    }
  }, [isModalOpen]);  // When modal opens, update chart if data is present

  // Effect to handle modal chart creation
  useEffect(() => {
    if (isModalOpen && fullScreenChartContainerRef.current) {
      fullScreenChartRef.current = createChart(fullScreenChartContainerRef.current, {
        layout: { textColor: 'black', background: { type: 'solid', color: 'white' } },
        timeScale: { timeVisible: true },
        height: fullScreenChartContainerRef.current.offsetHeight,
        width: fullScreenChartContainerRef.current.offsetWidth,
      });

      fullScreenChartRef.current.applyOptions({
        localization: {
          priceFormatter: (price) =>
            price.toLocaleString('en-US', { maximumFractionDigits: 0 }),
        },
        priceScale: {
          mode: 0,
        },
      });

      // Add series to the modal chart
      seriesDataRef.current.forEach((data, symbol) => {
        const originalSeries = lineSeriesRef.current.get(symbol);
        const uniqueSortedData = Array.from(
          new Map(data.map((item) => [item.time, item])).values()
        ).sort((a, b) => a.time - b.time);

        const newSeries = fullScreenChartRef.current.addLineSeries({
          color: originalSeries.options().color,
          lineWidth: originalSeries.options().lineWidth,
          visible: originalSeries.options().visible,
          lastValueVisible: false, // Disable the default last value label
          priceLineVisible: false
        });
        newSeries.setData(uniqueSortedData);
        fullScreenSeriesRef.current.set(symbol, newSeries);

        // Create price line
        const lastDataPoint = uniqueSortedData[uniqueSortedData.length - 1];
        if (lastDataPoint) {
          const priceLine = newSeries.createPriceLine({
            price: lastDataPoint.value,
            color: newSeries.options().color,
            lineWidth: 1,
            lineStyle: LineStyle.Solid,
            axisLabelVisible: true,
            title: symbol,
            lineVisible: false // Add this
          });
          fullScreenPriceLinesRef.current.set(symbol, priceLine);
        }
      });
    }

    return () => {
      if (fullScreenChartRef.current) {
        // Remove price lines
        fullScreenPriceLinesRef.current.forEach((priceLine, symbol) => {
          const series = fullScreenSeriesRef.current.get(symbol);
          if (series && priceLine) {
            series.removePriceLine(priceLine);
          }
        });
        fullScreenPriceLinesRef.current.clear();

        fullScreenChartRef.current.remove();
        fullScreenChartRef.current = null;
        fullScreenSeriesRef.current.clear();
      }
    };
  }, [isModalOpen]);
  
  // Effect to update modal chart when data changes
  useEffect(() => {
    if (isModalOpen && fullScreenChartRef.current) {
      // For each symbol, update the series with the new data
      seriesDataRef.current.forEach((data, symbol) => {
        const series = fullScreenSeriesRef.current.get(symbol);
        if (series) {
          const uniqueSortedData = Array.from(
            new Map(data.map((item) => [item.time, item])).values()
          ).sort((a, b) => a.time - b.time);
  
          series.setData(uniqueSortedData);
  
          // Update price line
          if (fullScreenPriceLinesRef.current.has(symbol)) {
            const oldPriceLine = fullScreenPriceLinesRef.current.get(symbol);
            series.removePriceLine(oldPriceLine);
          }
  
          const lastDataPoint = uniqueSortedData[uniqueSortedData.length - 1];
          if (lastDataPoint) {
            const priceLine = series.createPriceLine({
              price: lastDataPoint.value,
              color: series.options().color,
              lineWidth: 1,
              lineStyle: LineStyle.Solid,
              axisLabelVisible: true,
              title: symbol,
              lineVisible: false, // Hide the horizontal line
            });
            fullScreenPriceLinesRef.current.set(symbol, priceLine);
          }
        }
      });
    }
  }, [seriesDataRef.current, isModalOpen]);
  

  useEffect(() => {
    chartRef.current = createChart(chartContainerRef.current, {
      layout: { textColor: 'black', background: { type: 'solid', color: 'white' } },
      timeScale: { timeVisible: true },
      height: 290,
      width: chartContainerRef.current ? chartContainerRef.current.offsetWidth : 600,
      crosshair: {
        horizontalLine: {
          visible: false, // Hide the horizontal line of the crosshair
        },
        verticalLine: {
          visible: true, // or false if you also want to hide vertical line
        },
      },
    });
    

    chartRef.current.applyOptions({
      localization: {
        priceFormatter: (price) => price.toLocaleString('en-US', { maximumFractionDigits: 0 }),
      },
      priceScale: {
        mode: 0, // Linear scale
      },
    });

    const initializeData = async () => {
      await fetchHistoricalData();
      await fetchCurrentData();
    };

    initializeData();

    const intervalID = setInterval(() => {
      fetchCurrentData();
    }, 10000);

    return () => {
      clearInterval(intervalID);
      if (chartRef.current) {
        chartRef.current.remove();
        chartRef.current = null;
      }
    };
  }, [market, type]);

  const toggleSeriesVisibility = (symbol) => {
    const series = lineSeriesRef.current.get(symbol);
    if (series) {
      const currentVisible = series.options().visible ?? true;
      series.applyOptions({ visible: !currentVisible });
    }
  };

  // Implement getOppositeColor function
  const getOppositeColor = (hexColor) => {
    hexColor = hexColor.replace('#', '');
    const r = parseInt(hexColor.substr(0, 2), 16);
    const g = parseInt(hexColor.substr(2, 2), 16);
    const b = parseInt(hexColor.substr(4, 2), 16);
    const compR = 255 - r;
    const compG = 255 - g;
    const compB = 255 - b;
    const compHex =
      '#' +
      ((1 << 24) + (compR << 16) + (compG << 8) + compB)
        .toString(16)
        .slice(1)
        .toUpperCase();
    return compHex;
  };

  // Function to highlight a series when a table row is clicked
  const handleRowClick = (symbol) => {
    if (selectedSymbol === symbol) {
      // Deselect the symbol
      setSelectedSymbol(null);
  
      // Reset all series to original colors and styles
      lineSeriesRef.current.forEach((series, sym) => {
        const originalColor = originalColorsRef.current.get(sym);
        series.applyOptions({
          color: originalColor,
          lineWidth: 2,
        });
      });
  
      // Recreate the price line for the symbol that was previously highlighted
      const series = lineSeriesRef.current.get(symbol);
      if (series) {
        // Remove old price line if any
        if (priceLinesRef.current.has(symbol)) {
          const oldPriceLine = priceLinesRef.current.get(symbol);
          series.removePriceLine(oldPriceLine);
        }
  
        // Get the last data point for this symbol
        const seriesData = seriesDataRef.current.get(symbol);
        if (seriesData && seriesData.length > 0) {
          const lastDataPoint = seriesData[seriesData.length - 1];
  
          // Create a new price line for the symbol
          const priceLine = series.createPriceLine({
            price: lastDataPoint.value,
            color: series.options().color,
            lineWidth: 1,
            lineStyle: LineStyle.Solid,
            axisLabelVisible: true,
            title: symbol,
            lineVisible: false,
          });
          priceLinesRef.current.set(symbol, priceLine);
        }
      }
  
    } else {
      // Highlight the clicked series
      setSelectedSymbol(symbol);
  
      const selectedSeries = lineSeriesRef.current.get(symbol);
      const seriesData = seriesDataRef.current.get(symbol);
  
      if (selectedSeries && chartRef.current) {
        // Remove the existing series and re-add to bring it on top
        chartRef.current.removeSeries(selectedSeries);
        const newSeries = chartRef.current.addLineSeries({
          color: selectedSeries.options().color,
          lineWidth: selectedSeries.options().lineWidth,
          lastValueVisible: false,
          priceLineVisible: false,
        });
        newSeries.setData(seriesData);
        lineSeriesRef.current.set(symbol, newSeries);
  
        const originalColor = originalColorsRef.current.get(symbol);
        const oppositeColor = getOppositeColor(originalColor);
  
        newSeries.applyOptions({
          color: oppositeColor,
          lineWidth: 4,
        });
  
        // Fade other series
        lineSeriesRef.current.forEach((s, sym) => {
          if (sym !== symbol) {
            s.applyOptions({
              color: earthToneColor,
              lineWidth: 2,
            });
          }
        });
  
        // Recreate the price line immediately after highlighting
        // so that the label/title is shown right away.
        if (priceLinesRef.current.has(symbol)) {
          const oldPriceLine = priceLinesRef.current.get(symbol);
          newSeries.removePriceLine(oldPriceLine);
        }
  
        const lastDataPoint = seriesData[seriesData.length - 1];
        if (lastDataPoint) {
          const priceLine = newSeries.createPriceLine({
            price: lastDataPoint.value,
            color: newSeries.options().color,
            lineWidth: 1,
            lineStyle: LineStyle.Solid,
            axisLabelVisible: true,
            title: symbol,
            lineVisible: false,
          });
          priceLinesRef.current.set(symbol, priceLine);
        }
  
        // Now the label/title should appear right when you highlight the series.
      }
    }
  };

  const handleRowClickModal = (symbol) => {
    if (selectedModalSymbol === symbol) {
      // Deselect
      setSelectedModalSymbol(null);
  
      // Reset all series to original colors and styles
      fullScreenSeriesRef.current.forEach((series, sym) => {
        const originalColor = originalColorsRef.current.get(sym);
        series.applyOptions({
          color: originalColor,
          lineWidth: 2,
        });
      });
  
      // Recreate the price line for the symbol that was previously highlighted in fullscreen
      const series = fullScreenSeriesRef.current.get(symbol);
      if (series) {
        // Remove old price line if any
        if (fullScreenPriceLinesRef.current.has(symbol)) {
          const oldPriceLine = fullScreenPriceLinesRef.current.get(symbol);
          series.removePriceLine(oldPriceLine);
        }
  
        const seriesData = seriesDataRef.current.get(symbol);
        if (seriesData && seriesData.length > 0) {
          const lastDataPoint = seriesData[seriesData.length - 1];
  
          const priceLine = series.createPriceLine({
            price: lastDataPoint.value,
            color: series.options().color,
            lineWidth: 1,
            lineStyle: LineStyle.Solid,
            axisLabelVisible: true,
            title: symbol,
            lineVisible: false,
          });
          fullScreenPriceLinesRef.current.set(symbol, priceLine);
        }
      }
  
    } else {
      // Highlight the clicked series in fullscreen
      setSelectedModalSymbol(symbol);
  
      const selectedSeries = fullScreenSeriesRef.current.get(symbol);
      const seriesData = seriesDataRef.current.get(symbol);
  
      if (selectedSeries && fullScreenChartRef.current) {
        // Remove and re-add the series to bring it to the front in fullscreen
        fullScreenChartRef.current.removeSeries(selectedSeries);
        const newSeries = fullScreenChartRef.current.addLineSeries({
          color: selectedSeries.options().color,
          lineWidth: selectedSeries.options().lineWidth,
          lastValueVisible: false,
          priceLineVisible: false,
        });
        newSeries.setData(seriesData);
        fullScreenSeriesRef.current.set(symbol, newSeries);
  
        const originalColor = originalColorsRef.current.get(symbol);
        const oppositeColor = getOppositeColor(originalColor);
  
        newSeries.applyOptions({
          color: oppositeColor,
          lineWidth: 4,
        });
  
        // Fade other series in fullscreen
        fullScreenSeriesRef.current.forEach((s, sym) => {
          if (sym !== symbol) {
            s.applyOptions({
              color: earthToneColor,
              lineWidth: 2,
            });
          }
        });
  
        // Recreate the price line immediately after highlighting in fullscreen
        if (fullScreenPriceLinesRef.current.has(symbol)) {
          const oldPriceLine = fullScreenPriceLinesRef.current.get(symbol);
          newSeries.removePriceLine(oldPriceLine);
        }
  
        const lastDataPoint = seriesData[seriesData.length - 1];
        if (lastDataPoint) {
          const priceLine = newSeries.createPriceLine({
            price: lastDataPoint.value,
            color: newSeries.options().color,
            lineWidth: 1,
            lineStyle: LineStyle.Solid,
            axisLabelVisible: true,
            title: symbol,
            lineVisible: false,
          });
          fullScreenPriceLinesRef.current.set(symbol, priceLine);
        }
      }
    }
  };
  
  
  const handleStartButtonClick = () => {
    if (fullScreenChartRef.current && seriesDataRef.current) {
      const timeScale = fullScreenChartRef.current.timeScale();
  
      // Find the earliest and latest times from all series
      let earliestTime = Infinity;
      let latestTime = -Infinity;
  
      seriesDataRef.current.forEach((data) => {
        if (data.length > 0) {
          if (data[0].time < earliestTime) {
            earliestTime = data[0].time;
          }
          if (data[data.length - 1].time > latestTime) {
            latestTime = data[data.length - 1].time;
          }
        }
      });
  
      if (earliestTime !== Infinity && latestTime !== -Infinity) {
        // Get the current visible range duration
        const visibleRange = timeScale.getVisibleRange();
        const rangeDuration = visibleRange.to - visibleRange.from;
  
        // Set the visible range starting from the earliest time
        timeScale.setVisibleRange({
          from: earliestTime,
          to: earliestTime + rangeDuration,
        });
      }
    }
  };
  
  const handleHalfDayButtonClick = () => {
    if (fullScreenChartRef.current && seriesDataRef.current) {
      const timeScale = fullScreenChartRef.current.timeScale();
  
      // Find the earliest and latest times from all series
      let earliestTime = Infinity;
      let latestTime = -Infinity;
  
      seriesDataRef.current.forEach((data) => {
        if (data.length > 0) {
          earliestTime = Math.min(earliestTime, data[0].time);
          latestTime = Math.max(latestTime, data[data.length - 1].time);
        }
      });
  
      if (earliestTime !== Infinity && latestTime !== -Infinity) {
        // Calculate the middle time
        const middleTime = (earliestTime + latestTime) / 2;
  
        // Get the current visible range duration
        const visibleRange = timeScale.getVisibleRange();
        const rangeDuration = visibleRange.to - visibleRange.from;
  
        // Set the visible range centered around the middle time
        timeScale.setVisibleRange({
          from: middleTime - rangeDuration / 2,
          to: middleTime + rangeDuration / 2,
        });
      }
    }
  };
  
  const handleEndDayButtonClick = () => {
    if (fullScreenChartRef.current && seriesDataRef.current) {
      const timeScale = fullScreenChartRef.current.timeScale();
  
      // Find the latest time from all series
      let latestTime = -Infinity;
  
      seriesDataRef.current.forEach((data) => {
        if (data.length > 0) {
          latestTime = Math.max(latestTime, data[data.length - 1].time);
        }
      });
  
      if (latestTime !== -Infinity) {
        // Get the current visible range duration
        const visibleRange = timeScale.getVisibleRange();
        const rangeDuration = visibleRange.to - visibleRange.from;
  
        // Set the visible range ending at the latest time
        timeScale.setVisibleRange({
          from: latestTime - rangeDuration,
          to: latestTime,
        });
      }
    }
  };
  
  const handleShowAllButtonClick = () => {
  if (fullScreenChartRef.current && seriesDataRef.current) {
    const timeScale = fullScreenChartRef.current.timeScale();

    // Find the earliest and latest times from all series
    let earliestTime = Infinity;
    let latestTime = -Infinity;

    seriesDataRef.current.forEach((data) => {
      if (data.length > 0) {
        earliestTime = Math.min(earliestTime, data[0].time);
        latestTime = Math.max(latestTime, data[data.length - 1].time);
      }
    });

    if (earliestTime !== Infinity && latestTime !== -Infinity) {
      // Set the visible range to show all data
      timeScale.setVisibleRange({
        from: earliestTime,
        to: latestTime,
      });
    }
  }
};

const renderTable = (startIndex, endIndex, onRowClick, selectedSym) => (
  <table className="rank-table" style={{ fontSize: '11.5px', fontFamily: 'Arial, sans-serif' }}>
    <thead>
      <tr>
        <th style={{ textAlign: 'left', fontFamily: 'Arial, sans-serif' }}>Rank</th>
        <th style={{ textAlign: 'left', fontFamily: 'Arial, sans-serif' }}>Symbol</th>
        <th style={{ textAlign: 'left', fontFamily: 'Arial, sans-serif' }}>Value</th>
      </tr>
    </thead>
    <tbody>
      {tableData
        .sort((a, b) => a.rank - b.rank)
        .slice(startIndex, endIndex)
        .map((row, index) => (
          <tr
            key={index}
            style={{
              backgroundColor: row.color,
              cursor: 'pointer',
              fontWeight: row.symbol === selectedSym ? 'bold' : 'normal',
              fontFamily: 'Arial, sans-serif',
            }}
            onClick={() => onRowClick(row.symbol)}
          >
            <td>{row.rank}</td>
            <td>{row.symbol}</td>
            <td>{row.value.toLocaleString('en-US')}</td>
          </tr>
        ))}
    </tbody>
  </table>
);

return (
  <div className="chart-section" style={{ fontFamily: 'Arial, sans-serif' }}>
    <h3
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      {title}
      <button onClick={() => setIsModalOpen(true)} className="expand-button">
        🔍
      </button>
    </h3>
    <div
      className="chart-table-container"
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '20px',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      {/* Chart Container */}
      <div
        ref={chartContainerRef}
        className="chart-container"
        style={{ width: '60%', position: 'relative', fontFamily: 'Arial, sans-serif' }}
      >
        <button
          onClick={() => setDropdownOpen(!dropdownOpen)}
          style={{
            position: 'absolute',
            top: 5,
            right: 5,
            zIndex: 10,
            fontFamily: 'Arial, sans-serif',
          }}
        >
          Show/Hide Lines
        </button>
        {dropdownOpen && (
          <div
            style={{
              position: 'absolute',
              top: '40px',
              right: 5,
              background: 'white',
              border: '1px solid #ccc',
              padding: '10px',
              zIndex: 20,
              maxHeight: '200px',
              overflowY: 'scroll',
              fontFamily: 'Arial, sans-serif',
            }}
          >
            {Array.from(lineSeriesRef.current.keys()).map((symbol) => (
              <div key={symbol}>
                <input
                  id={`toggle-${symbol}`}
                  type="checkbox"
                  checked={lineSeriesRef.current.get(symbol)?.options().visible ?? true}
                  onChange={() => toggleSeriesVisibility(symbol)}
                />
                <label htmlFor={`toggle-${symbol}`}>{symbol}</label>
              </div>
            ))}
          </div>
        )}
      </div>
      {/* Table Container for ranks 0-10 */}
      <div
        className="table-container"
        style={{
          width: '20%',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          fontFamily: 'Arial, sans-serif',
        }}
      >
        {renderTable(0, 10, handleRowClick, selectedSymbol)}
      </div>

      {/* Table Container for ranks 11-20 */}
      <div
        className="table-container"
        style={{
          width: '20%',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          fontFamily: 'Arial, sans-serif',
        }}
      >
        {renderTable(10, 20, handleRowClick, selectedSymbol)}
      </div>
    </div>
    {/* Modal for Full-Screen Chart */}
    {isModalOpen && (
      <div
        className="modal-overlay"
        onClick={() => setIsModalOpen(false)}
        style={{ fontFamily: 'Arial, sans-serif' }}
      >
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <button
            className="close-button"
            onClick={() => setIsModalOpen(false)}
            style={{ fontFamily: 'Arial, sans-serif' }}
          >
            X
          </button>
          {/* Title and Navigation Buttons */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '10px',
              fontFamily: 'Arial, sans-serif',
            }}
          >
            <h3>{title}</h3>
            <div style={{ display: 'flex', gap: '10px', fontFamily: 'Arial, sans-serif' }}>
              <button onClick={handleStartButtonClick} className="nav-button">
                Start
              </button>
              <button onClick={handleHalfDayButtonClick} className="nav-button">
                Half-Day
              </button>
              <button onClick={handleEndDayButtonClick} className="nav-button">
                End-Day
              </button>
              <button onClick={handleShowAllButtonClick} className="nav-button">
                Show All
              </button>
            </div>
          </div>
          <div
            className="chart-table-container"
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '20px',
              fontFamily: 'Arial, sans-serif',
            }}
          >
            {/* Full-Screen Chart */}
            <div
              ref={fullScreenChartContainerRef}
              className="chart-container"
              style={{
                width: '80%',
                height: '80vh',
                position: 'relative',
                fontFamily: 'Arial, sans-serif',
              }}
            ></div>
            {/* Table */}
            <div
              className="table-container"
              style={{
                width: '20%',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                overflowY: 'auto',
                maxHeight: '80vh',
                fontFamily: 'Arial, sans-serif',
              }}
            >
              {renderTable(0, 20, handleRowClickModal, selectedModalSymbol)}
            </div>
          </div>
        </div>
      </div>
    )}
  </div>
);
};

const Money_in_out = () => (
<div
  className="dashboard"
  style={{
    display: 'flex',
    justifyContent: 'center',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  }}
>
  <div style={{ maxWidth: '1800px', width: '100%' }}>
    <h1 style={{ textAlign: 'center', marginBottom: '20px', fontFamily: 'Arial, sans-serif' }}>
      Money In/Out Realtime
    </h1>
    <div
      className="sections"
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '20px',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      {/* SET100 and NON-SET100 Money In (+) */}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <ChartSectionWithMultipleLines title="SET100 Money In (+)" market="SET100" type="in" />
        <ChartSectionWithMultipleLines
          title="NON-SET100 Money In (+)"
          market="NON-SET100"
          type="in"
        />
      </div>

      {/* SET100 and NON-SET100 Money Out (-) */}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <ChartSectionWithMultipleLines title="SET100 Money Out (-)" market="SET100" type="out" />
        <ChartSectionWithMultipleLines
          title="NON-SET100 Money Out (-)"
          market="NON-SET100"
          type="out"
        />
      </div>

      {/* MAI and WARRANT Money In (+) */}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <ChartSectionWithMultipleLines title="MAI Money In (+)" market="MAI" type="in" />
        <ChartSectionWithMultipleLines title="WARRANT Money In (+)" market="WARRANT" type="in" />
      </div>

      {/* MAI and WARRANT Money Out (-) */}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <ChartSectionWithMultipleLines title="MAI Money Out (-)" market="MAI" type="out" />
        <ChartSectionWithMultipleLines title="WARRANT Money Out (-)" market="WARRANT" type="out" />
      </div>
    </div>
  </div>
</div>
);

export default Money_in_out;