import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // ใช้ hook navigate
import ChartSectionWithMultipleLines from './ChartSectionWithMultipleLines';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';

import { FaSearch } from 'react-icons/fa'; // นำเข้าไอคอนแว่นขยาย


const DashboardHis = () => {
  const [selectedDate, setSelectedDate] = useState(new Date()); // State ของวันที่ร่วมกัน
  const navigate = useNavigate(); // hook สำหรับ navigate

  const chartConfigs = [
    { title: 'SET100 Money In', market: 'SET100', type: 'in' },
    { title: 'SET100 Money Out', market: 'SET100', type: 'out' },
    { title: 'NON-SET100 Money In', market: 'NON-SET100', type: 'in' },
    { title: 'NON-SET100 Money Out', market: 'NON-SET100', type: 'out' },
    { title: 'MAI Money In', market: 'MAI', type: 'in' },
    { title: 'MAI Money Out', market: 'MAI', type: 'out' },
    { title: 'WARRANT Money In', market: 'WARRANT', type: 'in' },
    { title: 'WARRANT Money Out', market: 'WARRANT', type: 'out' },
  ];

  const handleExpand = (title, market, type, date) => {
    // กำหนด path ตามประเภทของกราฟ
    let path = '';
    if (market === 'SET100') {
      path = type === 'in' ? '/set-in' : '/set-out';
    } else if (market === 'NON-SET100') {
      path = type === 'in' ? '/non-in' : '/non-out';
    } else if (market === 'MAI') {
      path = type === 'in' ? '/mai-in' : '/mai-out';
    } else if (market === 'WARRANT') {
      path = type === 'in' ? '/warrant-in' : '/warrant-out';
    }
    
   // ส่ง selectedDate ไปพร้อมกับการนำทาง
   navigate(path, { state: { title, market, type, selectedDate: date } });
  };
  return (
    <div className="dashboard" style={{ padding: '30px' }}>
    <h1 style={{ marginLeft: '550px',fontFamily: 'Arial, Helvetica, sans-serif' }}>Money In/Out history</h1>
    <div className="date-picker-container" style={datePickerStyle}>
      <label htmlFor="date-picker" style={{ marginLeft: '600px',fontFamily: 'Arial, Helvetica, sans-serif' }}>Select Date : </label>
      <input
        id="date-picker"
        type="date"
        value={selectedDate.toISOString().split('T')[0]}
        onChange={(e) => setSelectedDate(new Date(e.target.value))}
      />
    </div>

      {/* แบ่งแถว 2 คอลัมน์ */}
       {/* แสดงกราฟและปุ่มแว่นขยาย */}
       <div className="chart-grid" style={chartGridStyle}>
        {chartConfigs.map((config, index) => (
          <div key={index} style={chartItemStyle}>
            <div style={chartTitleStyle}>
              {/* ปุ่มแว่นขยายข้างๆ ชื่อกราฟ */}
              <FaSearch
                style={iconStyle}
                onClick={() => handleExpand(config.title, config.market, config.type, selectedDate)}
              />
            </div>
            <ChartSectionWithMultipleLines
              title={config.title}
              market={config.market}
              type={config.type}
              selectedDate={selectedDate}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

// CSS-in-JS สำหรับการตั้งค่าปุ่มเลือกวันที่ให้ติดบนสุด
const datePickerStyle = {
  position: 'sticky',
  top: '0',
  zIndex: '1000',
  padding: '10px 0',
  marginBottom: '20px',
  textAlign: 'left',
};

const chartGridStyle = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  rowGap: '7px', // ช่องว่างระหว่างแถว
  columnGap: '110px', // ช่องว่างระหว่างคอลัมน์
};

const chartItemStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
};

const chartTitleStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
};

const iconStyle = {
  cursor: 'pointer',
  marginLeft: '320px',
  marginBottom: '-130px',
  fontSize: '20px',
  zIndex: '1000',
};

export default DashboardHis;