import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // ใช้ useLocation เพื่อดึงข้อมูล state
import ChartSectionWithMultipleLinesPre from './ChartSectionWithMultipleLinesPre';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';

const DashboardSetIn = () => {
  // ดึงข้อมูล selectedDate จาก state ที่ส่งมาผ่าน React Router
  const location = useLocation();
  const { selectedDate: initialDate } = location.state || { selectedDate: new Date() };

  const [selectedDate, setSelectedDate] = useState(initialDate); // ใช้ initialDate จาก URL

  const chartConfigs = [
    { title: 'WARRANT Money In', market: 'WARRANT', type: 'in' },
  ];

  return (
    <div className="dashboard">
      {/* ปุ่มเลือกวันที่อยู่บนสุด */}
      <h1 style={{ marginLeft: '550px',fontFamily: 'Arial, Helvetica, sans-serif' }}>Money In/Out history</h1>
      <div className="date-picker-container" style={datePickerStyle}>
      <label htmlFor="date-picker" style={{ marginLeft: '590px',fontFamily: 'Arial, Helvetica, sans-serif' }}>Select Date : </label>
      <input
        id="date-picker"
        type="date"
        value={selectedDate.toISOString().split('T')[0]}
        onChange={(e) => setSelectedDate(new Date(e.target.value))}
      />
    </div>

      {/* แบ่งแถว 2 คอลัมน์ */}
      <div className="chart-grid" style={chartGridStyle}>
        {chartConfigs.map((config, index) => (
          <ChartSectionWithMultipleLinesPre
            key={index}
            title={config.title}
            market={config.market}
            type={config.type}
            selectedDate={selectedDate} // ส่งวันที่ไปเป็น props
          />
        ))}
      </div>
    </div>
  );
};

// ใช้ CSS-in-JS สำหรับจัด Layout แบบ 2 คอลัมน์
const chartGridStyle = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '20px',
};

// CSS-in-JS สำหรับการตั้งค่าปุ่มเลือกวันที่ให้ติดบนสุด
const datePickerStyle = {
  position: 'sticky',
  top: '0', // ติดอยู่ที่บนสุด
  zIndex: '1000', // ให้มีลำดับการแสดงผลสูง

  padding: '10px 0', // การตั้งค่าระยะห่าง
  marginBottom: '20px', // ระยะห่างจากเนื้อหาข้างล่าง
  textAlign: 'left', // จัดตําแหน่งข้อความให้อยู่ซ้าย
};

export default DashboardSetIn;
