import React, { useState, useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';
import { useDispatch, useSelector } from "react-redux";
// import './ValueOfTime.css';
// import config from '../config/config.json';

const ValueOfTime = () => {
    const [symbol, setSymbol] = useState('');
    const [date, setDate] = useState('');
    const [dailyData, setDailyData] = useState(null);
    const [intradayData, setIntradayData] = useState(null);
    const [loading, setLoading] = useState(false);

    // Chart References
    const priceChartRef = useRef();
    const exceedFreqChartRef = useRef();
    const intradayPriceChartRef = useRef();
    const exceedValueChartRef = useRef();
    const intradayNetChartRef = useRef();

    const csrfToken = useSelector((state) => state.csrfToken);
    
    const clearCharts = () => {
        [priceChartRef, exceedFreqChartRef, exceedValueChartRef, intradayPriceChartRef, intradayNetChartRef].forEach(ref => {
            if (ref.current && ref.current._chart) {
                ref.current._chart.remove();
                ref.current._chart = null;
            }
        });
    };
    
    const fetchDailyData = async (symbol) => { 
        try {
            const response = await fetch(`${window.config.base_api_url}/vot?symbol=${symbol}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'xsrf-token': csrfToken,
                },
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const data = await response.json();
            setDailyData(data.dailyData); 
            console.log("VOT Daily response data:", data);  
            return data;
        } catch (error) {
            console.error('Error fetching daily data:', error);
            return { error: "Unable to fetch VOT daily response" }; 
        }
    };
    
    const fetchIntradayData = async (symbol, date) => { 
        try {
            const response = await fetch(`${window.config.base_api_url}/vot?symbol=${symbol}&date=${date}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'xsrf-token': csrfToken,
                },
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const data = await response.json();
            setIntradayData(data); 
            console.log("VOT Intraday response data:", data);  
            return data;
        } catch (error) {
            console.error('Error fetching intraday data:', error);
            return { error: "Unable to fetch VOT intraday response" }; 
        }
    };


    const fetchData = async () => {
        if (!symbol || !date) {
            console.error('Both Symbol and Date are required');
            return;
        }

        setLoading(true);
        try {
            // Clear existing charts before creating new ones
            clearCharts();

            // Fetch daily and intraday data simultaneously
            const [dailyResponse, intradayResponse] = await Promise.all([
                fetchDailyData(symbol),
                fetchIntradayData(symbol, date)
            ]);

            // You can update the state or handle the fetched data as needed
            console.log('Fetched data:', { dailyResponse, intradayResponse });

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    // Utility function to sort data by time in ascending order
    const sortByTimeAscending = (data) => {
        return data.sort((a, b) => a.time - b.time);
    };

    // Check if both inputs are filled
    const areInputsFilled = () => symbol && date;
    
    // Sync visible range between two charts
    const syncVisibleRange = (chart1, chart2) => {
        chart1.timeScale().subscribeVisibleLogicalRangeChange((timeRange) => {
            chart2.timeScale().setVisibleLogicalRange(timeRange);
        });

        chart2.timeScale().subscribeVisibleLogicalRangeChange((timeRange) => {
            chart1.timeScale().setVisibleLogicalRange(timeRange);
        });
    };

    // Sync crosshair between two charts
    const getCrosshairDataPoint = (series, param) => {
        if (!param.time) {
            return null;
        }
        const dataPoint = param.seriesData.get(series);
        return dataPoint || null;
    };

    const syncCrosshair = (chart, series, dataPoint) => {
        if (dataPoint) {
            chart.setCrosshairPosition(dataPoint.value, dataPoint.time, series);
        } else {
            chart.clearCrosshairPosition();
        }
    };

    const syncCrosshairMove = (chart1, series1, chart2, series2) => {
        chart1.subscribeCrosshairMove((param) => {
            const dataPoint = getCrosshairDataPoint(series1, param);
            syncCrosshair(chart2, series2, dataPoint);
        });

        chart2.subscribeCrosshairMove((param) => {
            const dataPoint = getCrosshairDataPoint(series2, param);
            syncCrosshair(chart1, series1, dataPoint);
        });
    };

    const createChartContainer = (container, options) => {
        const chart = createChart(container, options);
        container._chart = chart; // Save reference to prevent duplicates
        return chart;
    };

    // Create chart for price data
    const createPriceChart = (container, priceData) => {
        const chart = createChartContainer(container, {
            width: container.clientWidth,
            height: container.clientHeight,
        });
        const series = chart.addLineSeries({ color: 'green' });
        series.setData(priceData.map(item => ({
            time: new Date(item.date).getTime() / 1000, // Convert to timestamp
            value: item.close,
        })));
        return { chart, series };
    };

    // Create chart for exceed frequency
    const createExceedFreqChart = (container, exceedData) => {
        const chart = createChartContainer(container, {
            width: container.clientWidth,
            height: container.clientHeight,
        });
        const series = chart.addLineSeries({ color: 'blue' });
        series.setData(exceedData.map(item => ({
            time: new Date(item.date).getTime() / 1000, // Convert to timestamp
            value: item.n_exceed,
        })));
        return { chart, series };
    };

    // Create chart for exceed value
    const createExceedValueChart = (container, exceedData) => {
        const chart = createChartContainer(container, {
            width: container.clientWidth,
            height: container.clientHeight,
        });
        const series = chart.addLineSeries({ 
            color: 'red',
            priceFormat: {
                type: 'volume',
                minMove: 1,
                precision: 2,
            },
        });
        series.setData(exceedData.map(item => ({
            time: new Date(item.date).getTime() / 1000, // Convert to timestamp
            value: item.val_exceed,
        })));
        return { chart, series };
    };

    // Add this function to convert the timestamp to UNIX
    const parseDateString = (dateString) => {
        return new Date(dateString.replace(" ", "T") + "Z").getTime() / 1000;
    };

    // Create chart for intraday price
    const createIntradayPriceChart = (container, priceData, exceedDate) => {
        const chart = createChartContainer(container, {
            width: container.clientWidth,
            height: container.clientHeight,
            timeScale: {
                timeVisible: true,
                secondsVisible: true,
            },
        });
        const series = chart.addLineSeries({ color: 'green' });
        series.setData(sortByTimeAscending(priceData.map(item => ({
            time: new Date(item.time).getTime() / 1000,
            value: item.last,
        }))));

        // Add markers
        const markers = [];
        exceedDate.up.forEach((time) => {
            markers.push({
                time: parseDateString(time),
                position: 'aboveBar',
                color: 'green',
                shape: 'arrowUp',
                text: 'U',
            });
        });

        exceedDate.down.forEach((time) => {
            markers.push({
                time: parseDateString(time),
                position: 'belowBar',
                color: 'red',
                shape: 'arrowDown',
                text: 'D',
            });
        });

        // Sort markers by ascending time
        markers.sort((a, b) => a.time - b.time);
        series.setMarkers(markers);

        return { chart, series };
    };

    // Create chart for intraday net value
    const createIntradayNetChart = (container, exceedData, exceedDate) => {
        const chart = createChartContainer(container, {
            width: container.clientWidth,
            height: container.clientHeight,
            timeScale: {
                timeVisible: true,
                secondsVisible: true,
            },
        });
        const series = chart.addLineSeries({ 
            color: 'purple',
            priceFormat: {
                type: 'volume',
                minMove: 1,
                precision: 2,
            },
        });
        series.setData(sortByTimeAscending(exceedData.map(item => ({
            time: new Date(item.time).getTime() / 1000,
            value: item.net_val,
        }))));

        // Add markers
        const markers = [];
        exceedDate.up.forEach((time) => {
            markers.push({
                time: parseDateString(time),
                position: 'aboveBar',
                color: 'green',
                shape: 'arrowUp',
                text: 'U',
            });
        });

        exceedDate.down.forEach((time) => {
            markers.push({
                time: parseDateString(time),
                position: 'belowBar',
                color: 'red',
                shape: 'arrowDown',
                text: 'D',
            });
        });

        // Sort markers by ascending time
        markers.sort((a, b) => a.time - b.time);
        series.setMarkers(markers);

        return { chart, series };
    };

    useEffect(() => {
        if (dailyData && !priceChartRef.current._chart) {
            const { chart: priceChart, series: priceSeries } = createPriceChart(priceChartRef.current, dailyData.dailyPrice);
            const { chart: freqChart, series: freqSeries } = createExceedFreqChart(exceedFreqChartRef.current, dailyData.exceedData);
            const { chart: valueChart, series: valueSeries } = createExceedValueChart(exceedValueChartRef.current, dailyData.exceedData);
            
            // Sync charts' visible range
            syncVisibleRange(priceChart, freqChart);
            syncVisibleRange(priceChart, valueChart);
            syncVisibleRange(freqChart, valueChart);
    
            // Sync crosshair
            syncCrosshairMove(priceChart, priceSeries, freqChart, freqSeries);
            syncCrosshairMove(priceChart, priceSeries, valueChart, valueSeries);
            syncCrosshairMove(freqChart, freqSeries, valueChart, valueSeries);
        }
    
        if (intradayData && !intradayPriceChartRef.current._chart && !intradayNetChartRef.current._chart) {
            const { chart: intradayPriceChart, series: intradayPriceSeries } = createIntradayPriceChart(intradayPriceChartRef.current, intradayData.priceData, intradayData.exceedDate);
            const { chart: intradayNetChart, series: intradayNetSeries } = createIntradayNetChart(intradayNetChartRef.current, intradayData.exceedData, intradayData.exceedDate);
    
            // Sync charts' visible range
            syncVisibleRange(intradayPriceChart, intradayNetChart);
    
            // Sync crosshair
            syncCrosshairMove(intradayPriceChart, intradayPriceSeries, intradayNetChart, intradayNetSeries);
        }
    }, [dailyData, intradayData]);

    return (
        <div style={{ fontFamily: 'Arial, sans-serif', maxWidth: '1800px', paddingLeft: '50px' }}>

            <h1>Value of Time</h1>
            <div style={{ margin: '20px', display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <label>Symbol:</label>
                <input
                    value={symbol}
                    onChange={(e) => setSymbol(e.target.value)}
                    placeholder="Enter symbol"
                />
                <label>Select date:</label>
                <input
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                />
                <button onClick={fetchData} disabled={!areInputsFilled()}>Submit</button>
            </div>
            {loading && (
                <p style={{
                    fontSize: '24px',
                    fontWeight: 'bold',
                    color: '#000000',
                    textAlign: 'center',
                    marginTop: '20px'
                }}>
                    Loading...
                </p>
            )}
        
            <div
                ref={priceChartRef}
                style={{
                    position: 'relative',
                    height: '250px',
                    width: '100%'
                }}
            >
                <div style={{ position: "absolute", top: '2%', left: '2%', zIndex: 20, color: 'black' }}>Price</div>
            </div>
            
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
                <div
                    ref={exceedFreqChartRef}
                    style={{
                        position: 'relative',
                        height: '250px',
                        width: '100%'
                    }}
                >
                    <div style={{ position: "absolute", top: '2%', left: '2%', zIndex: 20, color: 'black' }}>Frequency Exceed</div>
                </div>
                <div
                    ref={intradayPriceChartRef}
                    style={{
                        position: 'relative',
                        height: '250px',
                        width: '100%'
                    }}
                >
                    <div style={{ position: "absolute", top: '2%', left: '2%', zIndex: 20, color: 'black' }}>Intraday Price</div>
                </div>
            </div>
            
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
                <div
                    ref={exceedValueChartRef}
                    style={{
                        position: 'relative',
                        height: '250px',
                        width: '100%'
                    }}
                >
                    <div style={{ position: "absolute", top: '2%', left: '2%', zIndex: 20, color: 'black' }}>Exceed Value</div>
                </div>
                <div
                    ref={intradayNetChartRef}
                    style={{
                        position: 'relative',
                        height: '250px',
                        width: '100%'
                    }}
                >
                    <div style={{ position: "absolute", top: '2%', left: '2%', zIndex: 20, color: 'black' }}>Intraday Net Value</div>
                </div>
            </div>
        </div>
    );
};

export default ValueOfTime;
