import React, { useEffect, useRef, useState, useCallback } from 'react';
import { createChart } from 'lightweight-charts';
// import axios from 'axios';
import { FaSearch, FaTimes, FaSearchMinus } from 'react-icons/fa'; // นำเข้าไอคอนแว่นขยายและปิด
import { useDispatch, useSelector } from 'react-redux';

const API_BASE_URL = "http://172.18.1.81:3100/money_in_out/hist/";

const getRandomColor = () => {
  const softDarkColors = [
    "#FF8080", "#FF9966", "#FFCC66", "#FFCC00", 
    "#66CC66", "#66FF66", "#66CC99", "#66CCCC", 
    "#66CCFF", "#6699FF", "#9966FF", "#CC66FF", 
    "#FF66FF", "#FF66CC", "#FF8080", "#FF9966", 
    "#99CC66", "#CCFF66", "#FFCC66", "#FF6666"
  ];
  return softDarkColors[Math.floor(Math.random() * softDarkColors.length)];
};

// ฟังก์ชันปรับเวลาให้ตรงกับเขตเวลาท้องถิ่น
const adjustToLocalTime = (timestamp) => {
  const date = new Date(timestamp * 1000); // สร้าง Date จาก timestamp
  const timezoneOffset = date.getTimezoneOffset() * 60; // หาค่าชดเชย timezone ในหน่วยวินาที
  return timestamp - timezoneOffset; // ปรับ timestamp ให้ตรงกับ Local Time
};


const ChartSectionWithMultipleLinesPre = ({ title, market, type, selectedDate }) => {
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);
  const lineSeriesRef = useRef(new Map());
  const highlightedSeriesRef = useRef(null); // เก็บซีรีส์ที่ถูกไฮไลต์
  const [tableData, setTableData] = useState([]);
  const [previousData, setPreviousData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null); // เก็บ symbol ของแถวที่ถูกเลือก
  const [isFullscreen, setIsFullscreen] = useState(false); // สถานะโหมดขยาย
  const [highlightedSymbol, setHighlightedSymbol] = useState(null);
  const [tag, setTag] = useState(null); // เพิ่ม state สำหรับ tag
  const [visibleSymbols, setVisibleSymbols] = useState([]); // เก็บชื่อหุ้นที่จะแสดงใน UI
  const priceToSymbolMap = useRef(new Map());
  const csrfToken = useSelector((state) => state.csrfToken)
 
  const highlightLineSeries = (symbol) => {
    if (highlightedSymbol === symbol) return;
  
    setHighlightedSymbol(symbol); // อัปเดต symbol ที่จะไฮไลท์
    resetHighlight(); // ยกเลิกไฮไลท์ก่อนหน้า
  
    // ทำให้ค่า value ในแกน Y ของเส้นที่ถูกไฮไลท์แสดงชัดเจน
    const series = lineSeriesRef.current.get(symbol);
    if (series && series.series) {
      series.series.applyOptions({
        lineWidth: 4,
        color: 'green',
        lineStyle: 0,
      });
  
      // เปลี่ยนค่า value ในแกน Y ของเส้นที่ถูกไฮไลท์ให้เด่นชัด
      chartRef.current.applyOptions({
        localization: {
          priceFormatter: (price) => {
            const symbol = getSymbolForPrice(price);
            return `${price.toLocaleString('en-US', { maximumFractionDigits: 0 })} ${symbol}`;
          },
        },
      });
  
      highlightedSeriesRef.current = series.series;
    }
  
    // ทำให้เส้นอื่นๆ จางลง
    lineSeriesRef.current.forEach((series, otherSymbol) => {
      if (otherSymbol !== symbol && series.series) {
        series.series.applyOptions({
          lineWidth: 1, // ทำให้เส้นอื่นๆ บางลง
          color: 'rgba(181, 101, 29, 0.7)', // ทำให้เส้นอื่นๆ จางลง
        });
      }
    });
  };


// ฟังก์ชันยกเลิกไฮไลต์
const resetHighlight = () => {
  lineSeriesRef.current.forEach((series, symbol) => {
    if (series && series.series) {
      series.series.applyOptions({
        lineWidth: 2, // คืนความหนาของเส้น
        color: getRandomColor(), // เปลี่ยนสีเป็นสีสุ่ม
      });
    }
  });
  setHighlightedSymbol(null); // รีเซ็ตการเลือก symbol ที่ถูกไฮไลท์
};

// ฟังก์ชันจัดการคลิกที่กราฟ
const handleChartClick = (e) => {
  // ตรวจสอบว่าไม่ได้คลิกในตารางหรือกราฟ
  const isClickInsideTable = e.target.closest('.rank-table');
  const isClickInsideChart = chartRef.current && chartRef.current.container.contains(e.target);

  // ถ้าไม่ได้คลิกในตารางหรือกราฟ (คลิกที่พื้นที่ว่าง)
  if (!isClickInsideTable && !isClickInsideChart) {
    resetHighlight(); // ยกเลิกการไฮไลท์
  }
};

useEffect(() => {
  const chartContainer = chartRef.current?.container;

  // ตรวจสอบว่า chartContainer มีอยู่จริง
  if (chartContainer) {
    chartContainer.addEventListener('click', handleChartClick);
  }

  return () => {
    if (chartContainer) {
      chartContainer.removeEventListener('click', handleChartClick);
    }
  };
}, []); // ใช้ empty array เพื่อให้ติดตั้งครั้งเดียวตอนเริ่มต้น



const onClickRow = (symbol) => {
  if (symbol === highlightedSymbol) {
    resetHighlight(); // ถ้าคลิกที่ symbol ที่ไฮไลท์อยู่ ให้ยกเลิกการไฮไลท์
  } else {
    setSelectedRow(symbol); // อัพเดท selectedRow
    highlightLineSeries(symbol); // ไฮไลต์เส้นกราฟที่ตรงกับแถว

    // ดึง value จาก tableData
    const rowData = tableData.find((row) => row.symbol === symbol);
    const value = rowData ? rowData.value : 'N/A'; // ถ้าไม่มี value ให้แสดง 'N/A'

    // อัพเดท tag ให้แสดง symbol และ value
    setTag(`Symbol: ${symbol}  -- Value: ${value.toLocaleString('en-US', { maximumFractionDigits: 0 })}`);
  }
};


  // ฟังก์ชันขยายกราฟ
  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen); // สลับสถานะการขยาย
  };

  // ตั้งค่า Event Listener สำหรับการคลิก
  useEffect(() => {
    document.addEventListener('click', handleChartClick);

    return () => {
      document.removeEventListener('click', handleChartClick);
    };
  }, []);

  // ฟังก์ชันกำหนดสีของแถวในตาราง
  const getRowColor = (symbol, value) => {
    if (selectedRow === null) return 'white'; // คืนค่า white เมื่อไม่มีแถวถูกเลือก
    if (symbol === selectedRow) return '#e5c690'; // สีสำหรับแถวที่ถูกเลือก
    const previousValue = previousData[symbol];
    if (previousValue === undefined) return 'white';
    if (value > previousValue) return '#e8f5e9'; // สีเขียวสำหรับค่าที่เพิ่มขึ้น
    if (value < previousValue) return '#ffebee'; // สีแดงสำหรับค่าที่ลดลง
    return 'white';
  };

  const getSymbolForPrice = (price) => {
    const matchedData = tableData.find((item) => item.value === price);
    return matchedData ? matchedData.symbol : ''; // คืนชื่อหุ้นถ้าหาพบ
  };
  
 // ฟังก์ชันอัปเดตตัวเลือกการแสดงราคา
const updatePriceFormatter = () => {
  chartRef.current.applyOptions({
    localization: {
      priceFormatter: (price) => {
        const associatedSymbol = priceToSymbolMap.current.get(price);
        return associatedSymbol
          ? `${price.toLocaleString('en-US', { maximumFractionDigits: 0 })} (${associatedSymbol})`
          : `${price.toLocaleString('en-US', { maximumFractionDigits: 0 })}`;
      },
    },
  });
};
const fetchHistoricalData = async (date) => {
  const formattedDate = date.toISOString().split('T')[0];
  try {
    setIsLoading(true);

    // ใช้ fetch แทน axios
    const fetch_response = await fetch(`${window.config.base_api_url}/money_in_out/hist/${market}?date=${formattedDate}`, {
      headers: {
        'Content-Type': 'application/json',
        'xsrf-token': csrfToken, // ถ้ามีการใช้ xsrf-token
      },
    });

    const responseData = await fetch_response.json(); // ดึงข้อมูลในรูป JSON
    console.log('Fetched Data:', responseData); // Debug: ตรวจสอบข้อมูลที่ดึงมา

    if (responseData && responseData.data && (responseData.data.in || responseData.data.out)) {
      const dataSource = responseData.data.in; // เลือก data.in หรือ data.out

      const historicalData = {};

      Object.keys(dataSource.rank).forEach((time) => {
        Object.keys(dataSource.rank[time]).forEach((symbol) => {
          if (!historicalData[symbol]) {
            historicalData[symbol] = [];
          }

          const timestamp = Math.floor(new Date(`${formattedDate}T${time}`).getTime() / 1000);
          const value = dataSource.value[time][symbol];

          historicalData[symbol].push({
            time: adjustToLocalTime(timestamp),
            rank: dataSource.rank[time][symbol],
            value,
          });

          // เก็บ mapping ของ price -> symbol
          priceToSymbolMap.current.set(value, symbol);
        });
      });

      // อัพเดทข้อมูลในกราฟ
      Object.keys(historicalData).forEach((symbol) => {
        if (!lineSeriesRef.current.has(symbol)) {
          const series = chartRef.current.addLineSeries({
            color: getRandomColor(),
            lineWidth: 2,
            priceLineVisible: false, // ปิดการแสดงเส้นประ
          });
          lineSeriesRef.current.set(symbol, { series, symbol });
        }
        lineSeriesRef.current.get(symbol).series.setData(
          historicalData[symbol].map((dataPoint) => ({
            time: dataPoint.time,
            value: dataPoint.value,
          }))
        );
      });

      // อัพเดทข้อมูลในตาราง
      const updatedTableData = Object.keys(historicalData).map((symbol) => {
        const latestData = historicalData[symbol][historicalData[symbol].length - 1];
        return {
          rank: latestData.rank,
          symbol,
          value: latestData.value,
        };
      });

      setTableData(updatedTableData);
      updatePriceFormatter(); // อัพเดทรูปแบบการแสดงผลราคา
    } else {
      console.error('Invalid or missing data:', responseData);
    }

    setIsLoading(false);
  } catch (error) {
    console.error(`Error fetching data:`, error);
    setIsLoading(false);
  }
};


  useEffect(() => {
    chartRef.current = createChart(chartContainerRef.current, {
      layout: { textColor: 'black', background: { type: 'solid', color: 'white' } },
      timeScale: { timeVisible: true },
      height: 500,
      width: chartContainerRef.current ? chartContainerRef.current.offsetWidth : 600,
    });

    chartRef.current.applyOptions({
      priceScale: {
        mode: 0,
        borderColor: '#ddd',
        scaleMargins: {
          top: 0.2,
          bottom: 0.2,
        },
      },
      
    });

    fetchHistoricalData(selectedDate);

    return () => {
      if (chartRef.current) {
        chartRef.current.remove();
      }
    };
  }, [market, type, selectedDate]);

  const scrollToTime = useCallback((time) => {
    if (chartRef.current) {
      // เลื่อนกราฟไปยังช่วงเวลาที่เลือก
      chartRef.current.timeScale().setVisibleRange({
        from: time - 1800,  // 30 นาทีก่อน
        to: time + 1800,    // 30 นาทีหลัง
      });
  
      // อัปเดตการแสดงผลของชื่อ symbol หลังจากเลื่อนกราฟ
      updatePriceFormatter();
  
      // ถ้ามีการเลือก symbol ให้ไฮไลท์เส้น
      if (highlightedSymbol) {
        highlightLineSeries(highlightedSymbol); // ไฮไลท์เส้นกราฟที่มีการเลือก symbol
      }
    }
  }, [highlightedSymbol]);
  
  

// ฟังก์ชันเพื่อแปลงเวลาให้เป็น timestamp
const getTimestampForTime = (hours, minutes) => {
  const date = new Date(selectedDate);
  date.setHours(hours, minutes, 0, 0);
  return Math.floor(date.getTime() / 1000);  // แปลงเป็น timestamp
};

const zoomOut = () => {
  if (chartRef.current) {
    const currentRange = chartRef.current.timeScale().getVisibleLogicalRange();
    if (currentRange) {
      const center = (currentRange.from + currentRange.to) / 2;
      const newRange = {
        from: center - (center - currentRange.from) * 3.25,  // ซูมออก
        to: center + (currentRange.to - center) * 1.25,
      };

      // ตรวจสอบว่าไม่เกินขีดสุดของการซูม
      const maxVisibleRange = 2592000; // ขีดสุดของช่วงเวลา (เช่น 30 วัน = 2592000 วินาที)
      if (newRange.to - newRange.from <= maxVisibleRange) {
        chartRef.current.timeScale().setVisibleLogicalRange(newRange);
      }
    }
  }
};

const renderTables = () => {
  const sortedData = tableData.sort((a, b) => b.value - a.value).slice(0, 20);

  return (
    <table
      className="rank-table"
      style={{
        margin: '5px',
        marginTop: '-130px', // เลื่อนตารางขึ้นด้านบน
        marginLeft: '-20px',
        fontFamily: 'Arial, Helvetica, sans-serif',
        fontSize: '14px',
        borderCollapse: 'collapse',
        width: '100%',
      }}
    >
      <thead>
        <tr>
          <th style={{ padding: '5px', height: '30px', textAlign: 'center' }}>Rank</th>
          <th style={{ padding: '5px', height: '30px', textAlign: 'center' }}>Symbol</th>
          <th style={{ padding: '5px', height: '30px', textAlign: 'center' }}>Value</th>
        </tr>
      </thead>
      <tbody>
        {sortedData.map((row, index) => (
          <tr
            key={index}
            style={{
              backgroundColor: getRowColor(row.symbol, row.value),
              cursor: 'pointer',
              height: '32px',
            }}
            onClick={() => onClickRow(row.symbol)}
          >
            <td style={{ padding: '2px', textAlign: 'center' }}>{index + 1}</td>
            <td style={{ padding: '2px', textAlign: 'center' }}>{row.symbol}</td>
            <td style={{ padding: '2px', textAlign: 'center' }}>{row.value.toLocaleString()}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};


  //   return (
  //     <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '-70px',fontFamily: 'Arial, Helvetica, sans-serif' }}>
  //       {renderTable(firstTableData, 0)}
  //       {renderTable(secondTableData, 10)}
  //     </div>
  //   );
  // };

  return (
    <div
      className={`chart-section ${isFullscreen ? 'fullscreen' : ''}`}
      style={
        isFullscreen
          ? {
            position: 'absolute',
            top: 0,
            left: 0,
            padding: '20px',
            width: '100vw',
            height: '100vh',
            zIndex: 1000,
            backgroundColor: 'white', // เปลี่ยนสีพื้นหลัง
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)', // เพิ่มเงา
          }
          : {}
      }
    >
      {/* ส่วนหัวข้อกราฟพร้อมปุ่ม Expand */}
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px', marginTop: '10px',fontFamily: 'Arial, Helvetica, sans-serif' }}>
        <div style={{ display: 'flex', alignItems: 'center', }}>
          <h3 style={{
            margin: 0,            
            marginLeft: isFullscreen ? '750px' : '10px',
            textAlign: isFullscreen ? 'center' : 'left',

          }}>{title}</h3>
          {isLoading ? (
            <span style={{ fontStyle: 'italic', color: '#888', marginLeft: '10px' }}>กำลังโหลด...</span>
          ) : tableData.length === 0 ? (
            <span style={{ fontStyle: 'italic', color: '#888', marginLeft: '10px' }}>No Data Available</span>
          ) : null}
        </div>
        {/* <button
          onClick={toggleFullscreen}
          style={{
            padding: '5px 15px',
            marginRight: '550px',
            backgroundColor: 'none',
            color: 'black',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          {isFullscreen ? <FaTimes size={20} /> : <FaSearch size={20} />}
        </button> */}
      </div>
      <div style={{ marginLeft: '20px' }}>
      <button style={{ marginRight: '10px', backgroundColor: '#ebe3d8',fontFamily: 'Arial, Helvetica, sans-serif', border:'none', padding:'10px', borderRadius:'10px' }} onClick={() => scrollToTime(getTimestampForTime(17, 0))}>
          Start Graph
        </button>
        <button style={{ marginRight: '10px', backgroundColor: '#ebe3d8',fontFamily: 'Arial, Helvetica, sans-serif', border:'none', padding:'10px', borderRadius:'10px' }} onClick={() => scrollToTime(getTimestampForTime(19, 0))}>
          Half Graph
        </button>
        <button style={{ marginRight: '10px', backgroundColor: '#ebe3d8',fontFamily: 'Arial, Helvetica, sans-serif', border:'none', padding:'10px', borderRadius:'10px' }} onClick={() => scrollToTime(getTimestampForTime(23, 30))}>
          End Graph
        </button>

        {tag && <span
          style={{ marginLeft: '400px',
          backgroundColor:'#ebe3d8' ,
          padding:'12px',
          borderRadius:'10px',
           }}>{tag}</span>}

        
      </div>
      <div style={{marginLeft: '1000px',marginTop: '-40px' } }>       
        <button onClick={zoomOut} style={{ backgroundColor: '#ebe3d8' , border:'none', padding:'10px', borderRadius:'10px' }}>
          <FaSearchMinus size={20} /> {/* ซูมออก */}
        </button>
        </div>

      {/* ส่วนของกราฟและตาราง */}
      <div style={{ display: 'flex', padding: '30px' }}>
        <div
          ref={chartContainerRef}
          style={{
            flex: isFullscreen ? 0 : 1,
            marginLeft: isFullscreen ? '550px' : '0', // ระยะห่างจากกราฟหลักเมื่ออยู่ในโหมด fullscreen
            width: isFullscreen ? '1300px' : '1300px', // กราฟขยายเต็มจอในโหมด Fullscreen
            height: isFullscreen ? '60vh' : '900px', // ความสูงของกราฟในโหมด Fullscreen
            marginTop: isFullscreen ? '50px' : '0', // เว้นระยะในโหมด Fullscreen
            transition: 'all 0.3s ease', // การเปลี่ยนแปลงแบบนุ่มนวล
          }}
        ></div>


        <div style={{
          flex: isFullscreen ? 0 : 0.3,
          padding: isFullscreen ? '10px' : '0px',
          width: isFullscreen ? '500px' : '0px',
          height: isFullscreen ? '500px' : '0px',
          marginTop: isFullscreen ? '100px' : '0',
          marginLeft: isFullscreen ? '600px' : '50px',

        }}>{renderTables()}</div>
      </div>
    </div>
  );

};

export default ChartSectionWithMultipleLinesPre;
