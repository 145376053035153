import React, { useEffect, useState, useRef } from 'react'; 
import { createChart } from 'lightweight-charts';
import { useSelector } from "react-redux";
import './Flow.css';

function convertData(data, date_column = 'time', field = 'value', timezone = true) {
    const timeZoneOffset = timezone ? 7 * 60 * 60 * 1000 : 0;
    return data.map(d => ({
        time: (new Date(d[date_column]).getTime() + timeZoneOffset) / 1000,
        value: parseFloat(d[field]) || 0,
    }));
}

function TradingViewChart({ lineSeriesData, symbol, date }) {
    const chartContainerRef = useRef();

    useEffect(() => {
        const chart = createChart(chartContainerRef.current, {
            layout: { backgroundColor: '#ffffff', textColor: 'rgba(33, 56, 77, 1)' },
            grid: { vertLines: { color: 'rgba(197, 203, 206, 0.7)' }, horzLines: { color: 'rgba(197, 203, 206, 0.7)' } },
            rightPriceScale: { scaleMargins: { top: 0.3, bottom: 0.25 } },
            leftPriceScale: { scaleMargins: { top: 0.3, bottom: 0.25 }, visible: false },
            timeScale: {
                rightOffset: 12,
                barSpacing: 3,
                lockVisibleTimeRangeOnResize: true,
                rightBarStaysOnScroll: true,
                borderVisible: true,
                visible: true,
                timeVisible: true,
                secondsVisible: true,
                handleScroll: false,
            },
            watermark: {
                color: '#E4E5E6',
                visible: true,
                text: (symbol || '').toUpperCase(),
                fontSize: 50,
                horzAlign: 'center',
                vertAlign: 'center',
            },
        });

        const updateChartSize = () => {
            if (!chartContainerRef.current) return;
            chart.applyOptions({ width: chartContainerRef.current.clientWidth, height: chartContainerRef.current.clientHeight });
        };
        const resizeObserver = new ResizeObserver(() => requestAnimationFrame(updateChartSize));
        resizeObserver.observe(chartContainerRef.current);
        updateChartSize();

        const priceLineSeries = chart.addLineSeries({ color: 'rgba(0, 0, 255, 1)', lineWidth: 2, priceScaleId: 'right' });
        priceLineSeries.setData(lineSeriesData.price);

        const flowLineSeries = chart.addLineSeries({ color: 'rgba(255, 0, 0, 1)', lineWidth: 2, priceScaleId: 'left', priceFormat: { type: 'volume' } });
        flowLineSeries.setData(lineSeriesData.flow);

        return () => {
            resizeObserver.disconnect();
            chart.remove();
        };
    }, [lineSeriesData, symbol, date]);

    return <div ref={chartContainerRef} style={{ position: 'relative', marginTop: '3%', height: '80%', width: '100%' }} />;
}

function ChartContainer({ chartId, containerData, onDateChange, onSymbolChange, onSubmit, onDateReplicate, onSymbolReplicate }) {
    const { selectedDate, symbol, lineSeriesData } = containerData;

    const handleDateChange = (e) => {
        onDateChange(chartId, e.target.value);
    };

    const handleSymbolChange = (e) => {
        onSymbolChange(chartId, e.target.value);
    };

    const handleLocalSubmit = () => {
        onSubmit(chartId, selectedDate, symbol);
    };

    return (
        <div id={chartId} style={{ display: 'flex', flexDirection: 'column', maxWidth: '600px', margin: '0.2vw', backgroundColor: 'white' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-start', height: '3vh', alignItems: 'center', marginTop: '1%' }}>
                {/* Date input + button */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                        type="date"
                        value={selectedDate || ''}
                        onChange={handleDateChange}
                        className="form-control"
                        style={{ width: '180px', margin: '0.1vw 5px' }}
                    />
                    <button onClick={() => onDateReplicate(chartId)} style={{ width: '20px', height: '20px', fontSize: '10px', marginLeft: '5px' }}>+</button>
                </div>

                {/* Symbol input + button */}
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Symbol here"
                        value={symbol || ''}
                        onChange={handleSymbolChange}
                        style={{ width: '120px', margin: '0.1vw 5px' }}
                    />
                    <button onClick={() => onSymbolReplicate(chartId)} style={{ width: '20px', height: '20px', fontSize: '10px', marginLeft: '5px' }}>+</button>
                </div>

                {/* Submit button */}
                <button
                    className="btn btn-primary d-flex align-items-center justify-content-center"
                    onClick={handleLocalSubmit}
                    style={{ width: '60px', height: '25px', margin: '0.1vw 10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                    Submit
                </button>
            </div>
            {lineSeriesData && <TradingViewChart lineSeriesData={lineSeriesData} symbol={symbol || ''} date={selectedDate || ''} />}
        </div>
    );
}

export default function Flow() {
    const csrfToken = useSelector((state) => state.csrfToken);

    const initialContainers = Array.from({ length: 12 }).map((_, i) => ({
        chartId: `chart${i+1}`,
        selectedDate: '',
        symbol: '',
        lineSeriesData: null,
    }));

    const [containers, setContainers] = useState(initialContainers);

    const fetchData = async (date, symbol) => {
        if (!date || !symbol) {
            console.error('Please provide both symbol and date');
            return null;
        }

        const flowUrl = `${window.config.base_api_url}/flow?symbol=${symbol}&date=${date}`;
        try {
            const response = await fetch(flowUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'xsrf-token': csrfToken,
                },
            });
            if (!response.ok) throw new Error('Network response was not ok');
            const rawData = await response.json();
            if (!rawData || rawData.length === 0) {
                console.error('No data received');
                return null;
            }
            return rawData;
        } catch (error) {
            console.error('Error during fetch operation:', error.message);
            return null;
        }
    };

    const handleSubmit = async (chartId, date, symbol) => {
        const rawData = await fetchData(date, symbol);
        if (!rawData) return;
        const newLineSeriesData = {
            price: convertData(rawData, 'time', 'last'),
            flow: convertData(rawData, 'time', 'flow'),
        };

        setContainers(prev =>
            prev.map(c => c.chartId === chartId ? { ...c, lineSeriesData: newLineSeriesData } : c)
        );
    };

    const handleDateChange = (chartId, newDate) => {
        setContainers(prev =>
            prev.map(c => c.chartId === chartId ? { ...c, selectedDate: newDate } : c)
        );
    };

    const handleSymbolChange = (chartId, newSymbol) => {
        setContainers(prev =>
            prev.map(c => c.chartId === chartId ? { ...c, symbol: newSymbol } : c)
        );
    };

    const handleDateReplicate = (chartId) => {
        const sourceIndex = containers.findIndex(c => c.chartId === chartId);
        if (sourceIndex === -1) return;
        const sourceDate = containers[sourceIndex].selectedDate;
        if (!sourceDate) return;

        setContainers(prev => prev.map((c, idx) => {
            if (idx > sourceIndex) {
                return { ...c, selectedDate: sourceDate };
            }
            return c;
        }));
    };

    const handleSymbolReplicate = (chartId) => {
        const sourceIndex = containers.findIndex(c => c.chartId === chartId);
        if (sourceIndex === -1) return;
        const sourceSymbol = containers[sourceIndex].symbol;
        if (!sourceSymbol) return;

        setContainers(prev => prev.map((c, idx) => {
            if (idx > sourceIndex) {
                return { ...c, symbol: sourceSymbol };
            }
            return c;
        }));
    };

    return (
        <div className="flow-center-container">
            <div className="flow-content">
                <div className="chart-row">
                    {containers.slice(0,3).map((cont) => (
                        <ChartContainer
                            key={cont.chartId}
                            chartId={cont.chartId}
                            containerData={cont}
                            onSubmit={handleSubmit}
                            onDateChange={handleDateChange}
                            onSymbolChange={handleSymbolChange}
                            onDateReplicate={handleDateReplicate}
                            onSymbolReplicate={handleSymbolReplicate}
                        />
                    ))}
                </div>
                <div className="chart-row">
                    {containers.slice(3,6).map((cont) => (
                        <ChartContainer
                            key={cont.chartId}
                            chartId={cont.chartId}
                            containerData={cont}
                            onSubmit={handleSubmit}
                            onDateChange={handleDateChange}
                            onSymbolChange={handleSymbolChange}
                            onDateReplicate={handleDateReplicate}
                            onSymbolReplicate={handleSymbolReplicate}
                        />
                    ))}
                </div>
                <div className="chart-row">
                    {containers.slice(6,9).map((cont) => (
                        <ChartContainer
                            key={cont.chartId}
                            chartId={cont.chartId}
                            containerData={cont}
                            onSubmit={handleSubmit}
                            onDateChange={handleDateChange}
                            onSymbolChange={handleSymbolChange}
                            onDateReplicate={handleDateReplicate}
                            onSymbolReplicate={handleSymbolReplicate}
                        />
                    ))}
                </div>
                <div className="chart-row">
                    {containers.slice(9,12).map((cont) => (
                        <ChartContainer
                            key={cont.chartId}
                            chartId={cont.chartId}
                            containerData={cont}
                            onSubmit={handleSubmit}
                            onDateChange={handleDateChange}
                            onSymbolChange={handleSymbolChange}
                            onDateReplicate={handleDateReplicate}
                            onSymbolReplicate={handleSymbolReplicate}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}
