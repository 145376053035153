import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { TextField, Typography, Switch, Box, Divider, List, ListItem, ListItemText } from '@mui/material';
import { __fetching_data__ } from '../../components/api.js';

const base_api_url =
    process.env.NODE_ENV === "production"
        ? window.location.protocol === "https:"
            ? process.env.REACT_APP_API_HTTPS_URL
            : process.env.REACT_APP_API_HTTP_URL
        : "/application";

const NewsComponent = () => {
    const [searchText, setSearchText] = useState('');
    const [news, setNews] = useState([]);
    const csrfToken = useSelector((state) => state.csrfToken);

    // Fetch news from backend
    useEffect(() => {
        const fetchNews = async () => {
            try {
                const response = await __fetching_data__({
                    url: `${base_api_url}/fetch_news_type/`,
                    method: "GET",
                    headers: { "xsrf-token": csrfToken },
                });

                console.log(response)
                
                if (!response || !response.data) {
                    throw new Error('Invalid response data');
                }

                setNews(
                    response.data.news.map((item) => ({
                        id: item.word,
                        title: item.word,
                        enabled: item.status === 'access', // Convert status to boolean
                    }))
                );
            } catch (error) {
                console.error('Error fetching news:', error);
            }
        };
        fetchNews();
    }, [__fetching_data__, csrfToken]);

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };

    // Handle switch state change and update backend
    const handleSwitchChange = async (id, currentStatus) => {
        const newStatus = currentStatus ? 'inaccessible' : 'access';

        try {
            const response = await __fetching_data__({
                url: `${base_api_url}/change_news_type/`,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "xsrf-token": csrfToken,
                },
                body: JSON.stringify({
                    word: id,
                    status: newStatus,
                }),
            });

            if (!response || !response.status) {
                throw new Error('Failed to update news status');
            }

            // Update local state on success
            setNews((prevNews) =>
                prevNews.map((item) =>
                    item.id === id ? { ...item, enabled: !item.enabled } : item
                )
            );
        } catch (error) {
            console.error('Error updating news status:', error);
        }
    };

    const filteredNews = news.filter((item) =>
        item.title.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
      <Box
          sx={{
              maxWidth: 600,
              marginTop: '16px',
              marginLeft: 'auto',
              marginRight: 'auto',
              padding: 2,
              backgroundColor: 'white',
              borderRadius: 3,
              boxShadow: 2,
              fontFamily: 'Arial, sans-serif',
          }}
      >
          {/* Header */}
          <Typography variant="h4" gutterBottom>
              หัวข้อข่าว
          </Typography>

          {/* Search Bar */}
          <TextField
              fullWidth
              variant="outlined"
              placeholder="ค้นหาหัวข้อข่าว..."
              value={searchText}
              onChange={handleSearchChange}
              sx={{
                  marginBottom: 2,
                  borderRadius: '10px',
                  '& .MuiOutlinedInput-root': {
                      borderRadius: '10px',
                  },
              }}
          />

          <Divider sx={{ marginBottom: 2 }} />

          {/* News List */}
          <List>
              {filteredNews.map((item) => (
                  <ListItem
                      key={item.id}
                      sx={{
                          padding: 1,
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          border: '1px solid #ddd',
                          borderRadius: 2,
                          marginBottom: 1,
                          boxShadow: 0,
                      }}
                  >
                      <ListItemText primary={item.title} />
                      <Switch
                          checked={item.enabled}
                          onChange={() => handleSwitchChange(item.id, item.enabled)}
                          color="primary"
                      />
                  </ListItem>
              ))}
          </List>
      </Box>
  );
};

export default NewsComponent;
