import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ColorType, createChart, Rectangle } from 'lightweight-charts';
import DataTable from "./table";
import BasicTable from "./table";
import EnhancedTable from "./table";
import { __fetching_data__ } from '../../components/api.js';
import { useState } from "react";
import { CircularProgress } from "@mui/material";

import './index.css';

const base_api_url =
    process.env.NODE_ENV === "production"
        ? window.location.protocol === "https:"
            ? process.env.REACT_APP_API_HTTPS_URL
            : process.env.REACT_APP_API_HTTP_URL
        : "/application";

function DRPage() {
    const csrfToken = useSelector((state) => state.csrfToken);
    const [symbol, setSymbol] = useState([])
    let [data, setData] = useState([])
    const [abc, setAbc] = useState(0)
    const [isLoad, setLoad] = useState(false)

    useEffect(() => {
        callAPI()
    }, [])

    async function callAPI() {
        const response = await __fetching_data__({ url: `${base_api_url}/dr_list_symbol`, method: "GET", headers: { "xsrf-token": csrfToken } });
        console.log('response', response)
        if (response.status) {
            // data = response.data
            setData(response.data)
            // setAbc(20)
        }
    }

    function bgChart(name, data, chartName, lineColor) {
        const chartDiv = document.getElementById('chart-div')
        const filterContainer = document.createElement('div')
        filterContainer.setAttribute('id', name)
        chartDiv.append(filterContainer)
        // filterContainer.setAttribute('style', 'position: relative; overflow: visible; z-index:3;')
        const chart = createChart(document.getElementById(name),
            {
                width: 850,
                height: 250,
            }
        );
        chart.applyOptions({
            watermark: {
                visible: true,
                fontSize: 48,
                horzAlign: 'center',
                vertAlign: 'center',
                color: 'rgba(0, 0, 0, 0.1)',
                text: `${chartName}`,
            },

        });
        const lineSeries = chart.addLineSeries({ color: lineColor });
        lineSeries.setData(data);
        return [chart, lineSeries]
    }


    const inList = (data, lst) => {
        for (let i = 0; i < lst.length; i++) {
            if (data == lst[i]['time']) {
                return i
            }
        }
        return undefined
    }

    const clearDiv = () => {
        document.getElementById('chart1').remove()
        document.getElementById('chart2').remove()
        document.getElementById('chart3').remove()
    }

    const onClickTable = async (id) => {
        setLoad(true)
        const response = await __fetching_data__({ url: `${base_api_url}/dr_detail_symbol?id=${id}`, method: "GET", headers: { "xsrf-token": csrfToken } });
        console.log('response click', response)
        const data = []
        if (response.status) {
            for (let i = 0; i < response.data.chart3.length; i++) {
                const chartData = response.data.chart3[i]
                const dateData = chartData['date'].split(' ')[0].replaceAll('.', '-')
                let dataInList = inList(dateData, data)
                if (dataInList != undefined) {
                    data[dataInList]['value'] = Number(chartData['value'])
                } else {
                    data.push({ 'time': dateData, 'value': Number(chartData['value']) })
                }
            }
            clearDiv()
            function compare(a, b) {
                if (a.time < b.time) {
                    return -1;
                }
                if (a.time > b.time) {
                    return 1;
                }
                return 0;
            }
            data.sort(compare)
            response.data.chart1.sort(compare)
            response.data.chart2.sort(compare)
            var [chart1, mainSeries1] = bgChart('chart1', response.data.chart1, response.data.name.chart1, 'blue')
            var [chart2, mainSeries2] = bgChart('chart2', response.data.chart2, response.data.name.chart2, 'black')
            var [chart3, mainSeries3] = bgChart('chart3', data, response.data.name.chart3, 'green')
            // var [chart1, mainSeries1] = bgChart('chart1', response.data.chart1, response.data.name.chart1, 'blue')
            // var [chart2, mainSeries2] = bgChart('chart2', data, response.data.name.chart2, 'black')
            // var [chart3, mainSeries3] = bgChart('chart3', data, response.data.name.chart3, 'green')
            // var [chart3, mainSeries3] = bgChart('chart3',  response.data.chart1, response.data.name.chart3, 'green')
            chart1.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
                chart1.timeScale().setVisibleLogicalRange(timeRange);
                chart2.timeScale().setVisibleLogicalRange(timeRange);
                chart3.timeScale().setVisibleLogicalRange(timeRange);
            });

            chart2.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
                chart1.timeScale().setVisibleLogicalRange(timeRange);
                chart2.timeScale().setVisibleLogicalRange(timeRange);
                chart3.timeScale().setVisibleLogicalRange(timeRange);
            });

            chart3.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
                chart1.timeScale().setVisibleLogicalRange(timeRange);
                chart2.timeScale().setVisibleLogicalRange(timeRange);
                chart3.timeScale().setVisibleLogicalRange(timeRange);
            });

            function getCrosshairDataPoint(series, param) {
                if (!param.time) {
                    return null;
                }
                const dataPoint = param.seriesData.get(series);
                return dataPoint || null;
            }

            function syncCrosshair(chart, series, dataPoint) {
                if (dataPoint) {
                    chart.setCrosshairPosition(dataPoint.value, dataPoint.time, series);
                    return;
                }
                chart.clearCrosshairPosition();
            }
            chart1.subscribeCrosshairMove(param => {
                const dataPoint = getCrosshairDataPoint(mainSeries1, param);
                syncCrosshair(chart2, mainSeries2, dataPoint);
                syncCrosshair(chart3, mainSeries3, dataPoint);
            });
            chart2.subscribeCrosshairMove(param => {
                const dataPoint = getCrosshairDataPoint(mainSeries2, param);
                syncCrosshair(chart1, mainSeries1, dataPoint);
                syncCrosshair(chart3, mainSeries3, dataPoint);
            });
            chart3.subscribeCrosshairMove(param => {
                const dataPoint = getCrosshairDataPoint(mainSeries3, param);
                syncCrosshair(chart1, mainSeries1, dataPoint);
                syncCrosshair(chart2, mainSeries2, dataPoint);
                // syncCrosshair(chart3, mainSeries3, dataPoint);
            });
            setLoad(false)
        }
    }

    return (<div>
        <div style={{ display: 'flex', marginTop: '10px', marginLeft: '50px' }}>
            <div>
                <table>
                    <tr>
                        <th>DR/DRx</th>
                        <th>TradingView</th>
                    </tr>
                    {data.map(e => <tr style={{ cursor: 'pointer' }} onClick={() => {
                        onClickTable(e[0])
                    }}>
                        <td>{e[1]}</td>
                        {/* <td>{e[2]}</td> */}
                        <td>
                            <div class="tooltip">{e[2]}
                                <span class="tooltiptext">{e[4]}</span>
                            </div>
                        </td>
                    </tr>)}
                </table>
            </div>
            {isLoad && <div style={{ zIndex: 100, position: 'absolute', top: '30%', left: '60%' }}><CircularProgress /></div>}
            <div id="chart-div" style={{ width: '850px', marginLeft: '5px' }}>
                <div><div id="chart1"></div></div>
                <div><div id="chart2"></div></div>
                <div><div id="chart3"></div></div>
            </div>
            {/* {!isLoad && <div id="chart-div" style={{ width: '850px', marginLeft: '5px' }}>
                <div><div id="chart1"></div></div>
                <div><div id="chart2"></div></div>
                <div><div id="chart3"></div></div>
            </div>} */}
        </div>
    </div>)
}

export default DRPage