import React, { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';
// import axios from 'axios';
import { FaSearch, FaTimes } from 'react-icons/fa'; // นำเข้าไอคอนแว่นขยายและปิด
import { useDispatch, useSelector } from 'react-redux';

const API_BASE_URL = "http://172.18.1.81:3100/money_in_out/hist/";

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

// ฟังก์ชันปรับเวลาให้ตรงกับเขตเวลาท้องถิ่น
const adjustToLocalTime = (timestamp) => {
  const date = new Date(timestamp * 1000); // สร้าง Date จาก timestamp
  const timezoneOffset = date.getTimezoneOffset() * 60; // หาค่าชดเชย timezone ในหน่วยวินาที
  return timestamp - timezoneOffset; // ปรับ timestamp ให้ตรงกับ Local Time
};

const ChartSectionWithMultipleLines = ({ title, market, type, selectedDate }) => {
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);
  const lineSeriesRef = useRef(new Map());
  const highlightedSeriesRef = useRef(null); // เก็บซีรีส์ที่ถูกไฮไลต์
  const [tableData, setTableData] = useState([]);
  const [previousData, setPreviousData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null); // เก็บ symbol ของแถวที่ถูกเลือก
  const [isFullscreen, setIsFullscreen] = useState(false); // สถานะโหมดขยาย
  const csrfToken = useSelector((state) => state.csrfToken)

  // ฟังก์ชันยกเลิกไฮไลต์
  const resetHighlight = () => {
    if (highlightedSeriesRef.current) {
      highlightedSeriesRef.current.applyOptions({ lineWidth: 2 }); // คืนความหนาเส้นเดิม
      highlightedSeriesRef.current = null;
    }
    setSelectedRow(null); // ยกเลิกการเลือกแถว
  };

  // ฟังก์ชันไฮไลต์เส้นกราฟ
  const highlightLineSeries = (symbol) => {
    resetHighlight(); // ยกเลิกไฮไลต์ก่อนหน้า
    const series = lineSeriesRef.current.get(symbol);
    if (series) {
      series.applyOptions({ lineWidth: 4 }); // เพิ่มความหนาเส้น
      highlightedSeriesRef.current = series;
    }
  };

  // ฟังก์ชันจัดการคลิกที่แถวในตาราง
  const onClickRow = (symbol) => {
    setSelectedRow(symbol); // อัพเดท selectedRow
    highlightLineSeries(symbol); // ไฮไลต์เส้นกราฟที่ตรงกับแถว
  };

  // ฟังก์ชันจัดการคลิกที่พื้นที่ว่าง
  const handleChartClick = (e) => {
    if (!e.target.closest('.rank-table')) {
      // ถ้าไม่ได้คลิกในตาราง ให้ยกเลิกไฮไลต์
      resetHighlight();
    }
  };

  // ฟังก์ชันขยายกราฟ
  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen); // สลับสถานะการขยาย
  };

  const handleExpandClick = () => {
    onExpand(title, market, type, selectedDate); // เมื่อคลิกจะส่งข้อมูลไปหน้ารายละเอียด
  };

  // ตั้งค่า Event Listener สำหรับการคลิก
  useEffect(() => {
    document.addEventListener('click', handleChartClick);

    return () => {
      document.removeEventListener('click', handleChartClick);
    };
  }, []);

  // ฟังก์ชันกำหนดสีของแถวในตาราง
  const getRowColor = (symbol, value) => {
    if (symbol === selectedRow) return '#bbdefb'; // สีฟ้าสำหรับแถวที่ถูกเลือก
    const previousValue = previousData[symbol];
    if (previousValue === undefined) return 'white';
    if (value > previousValue) return '#e8f5e9'; // สีเขียวสำหรับค่าที่เพิ่มขึ้น
    if (value < previousValue) return '#ffebee'; // สีแดงสำหรับค่าที่ลดลง
    return 'white';
  };

 const fetchHistoricalData = async (date) => {
  const formattedDate = date.toISOString().split('T')[0];
  try {
    setIsLoading(true);

    // ใช้ fetch แทน axios
    const fetch_response = await fetch(`${window.config.base_api_url}/money_in_out/hist/${market}?date=${formattedDate}`, {
      headers: {
        'Content-Type': 'application/json',
        'xsrf-token': csrfToken, // ถ้ามีการใช้ xsrf-token
      },
    });

    const data = await fetch_response.json();  // ดึงข้อมูลในรูป JSON
    console.log(data);
    console.log("Fetched Data:", data);


    // ตรวจสอบว่า data.rank และ data.value มีค่าหรือไม่
    if (data && data.data && (data.data.in || data.data.out)) {
      const historicalData = {};

      // เลือกใช้ data.in หรือ data.out ตามความต้องการ
      const dataSource = data.data.in; // ใช้ in หรือ out แล้วแต่ที่คุณต้องการ

      Object.keys(dataSource.rank).forEach((time) => {
        Object.keys(dataSource.rank[time]).forEach((symbol) => {
          if (!historicalData[symbol]) {
            historicalData[symbol] = [];
          }

          const timestamp = Math.floor(new Date(`${formattedDate}T${time}`).getTime() / 1000);

          historicalData[symbol].push({
            time: adjustToLocalTime(timestamp), // ใช้ฟังก์ชันปรับเวลาท้องถิ่น
            rank: dataSource.rank[time][symbol],
            value: dataSource.value[time][symbol],
          });
        });
      });

      // แสดงข้อมูลกราฟ
      if (chartRef.current) {
        Object.keys(historicalData).forEach((symbol) => {
          if (!lineSeriesRef.current.has(symbol)) {
            lineSeriesRef.current.set(
              symbol,
              chartRef.current.addLineSeries({
                color: getRandomColor(),
                lineWidth: 2,
                priceLineVisible: false, // ปิดการแสดงเส้นประ
              })
            );
          }
          lineSeriesRef.current.get(symbol).setData(
            historicalData[symbol].map((dataPoint) => ({
              time: dataPoint.time,
              value: dataPoint.value,
            }))
          );
        });
      }

      // อัพเดทข้อมูลในตาราง
      const updatedTableData = Object.keys(historicalData).map((symbol) => {
        const symbolData = historicalData[symbol];
        const latestData = symbolData[symbolData.length - 1];

        return {
          rank: latestData.rank,
          symbol,
          value: latestData.value,
        };
      });

      const newPreviousData = {};
      updatedTableData.forEach((row) => {
        newPreviousData[row.symbol] = row.value;
      });

      setPreviousData(newPreviousData);
      setTableData(updatedTableData);
    } else {
      console.error('Invalid or missing data:', data);
    }

    setIsLoading(false);
  } catch (error) {
    console.error(`Error fetching historical data for ${market}:`, error);
    setIsLoading(false);
  }
};


  useEffect(() => {
    chartRef.current = createChart(chartContainerRef.current, {
      layout: { textColor: 'black', background: { type: 'solid', color: 'white' } },
      timeScale: { timeVisible: true },
      height: 290,
      width: chartContainerRef.current ? chartContainerRef.current.offsetWidth : 600,
    });

    chartRef.current.applyOptions({
      localization: {
        priceFormatter: (price) => price.toLocaleString('en-US', { maximumFractionDigits: 0 }),
      },
      priceScale: {
        mode: 0,
        borderColor: '#ddd',
        scaleMargins: {
          top: 0.2,
          bottom: 0.2,
        },
      },
    });

    fetchHistoricalData(selectedDate);

    return () => {
      if (chartRef.current) {
        chartRef.current.remove();
      }
    };
  }, [market, type, selectedDate]);

  const renderTables = () => {
    const firstTableData = tableData.sort((a, b) => b.value - a.value).slice(0, 10);
    const secondTableData = tableData.sort((a, b) => b.value - a.value).slice(10, 20);

    const renderTable = (data, startIndex) => (
      <table
       className="rank-table" 
       style={{ 
        marginLeft: '20px',
        marginTop:'30px',
        fontFamily: 'Arial, Helvetica, sans-serif',
        fontSize: '12px', // ลดขนาดฟอนต์
        borderCollapse: 'collapse', // ทำให้เส้นชิดกัน
        width: '100%', // ปรับขนาดตาราง
       }}>
        <thead>
          <tr>
            <th style={{ padding: '5px' }}>Rank</th>
            <th style={{ padding: '5px' }}>Symbol</th>
            <th style={{ padding: '5px' }}>Value</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr
              key={index}
              style={{ backgroundColor: getRowColor(row.symbol, row.value), cursor: 'pointer' }}
              onClick={() => onClickRow(row.symbol)}
            >
              <td style={{ padding: '5px', textAlign: 'center' }}>{startIndex + index + 1}</td>
              <td style={{ padding: '5px', textAlign: 'center' }}>{row.symbol}</td>
              <td style={{ padding: '5px', textAlign: 'center' }}>{row.value.toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '-70px' }}>
        {renderTable(firstTableData, 0)}
        {renderTable(secondTableData, 10)}
      </div>
    );
  };

  return (
    <div
      className={`chart-section ${isFullscreen ? 'fullscreen' : ''}`}
      style={
        isFullscreen
          ? {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            zIndex: 1000,
            backgroundColor: 'white', // เปลี่ยนสีพื้นหลัง
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)', // เพิ่มเงา
          }
          : {}
      }
    >
      {/* ส่วนหัวข้อกราฟพร้อมปุ่ม Expand */}
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px', marginTop: '50px',fontFamily: 'Arial, Helvetica, sans-serif' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h3 style={{
            margin: 0,
            marginLeft: isFullscreen ? '750px' : '10px',
            textAlign: isFullscreen ? 'center' : 'left',

          }}>{title}</h3>
          {isLoading ? (
            <span style={{ fontStyle: 'italic', color: '#888', marginLeft: '10px' }}>กำลังโหลด...</span>
          ) : tableData.length === 0 ? (
            <span style={{ fontStyle: 'italic', color: '#888', marginLeft: '10px' }}>No Data Available</span>
          ) : null}
        </div>
        {/* <button
          onClick={handleExpandClick}
          style={{
            padding: '5px 15px',
            marginRight: '550px',
            backgroundColor: 'none',
            color: 'black',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          {isFullscreen ? <FaTimes size={20} /> : <FaSearch size={20} />}
        </button> */}
      </div>

      {/* ส่วนของกราฟและตาราง */}
      <div style={{ display: 'flex', flexDirection: isFullscreen ? 'column' : 'row' }}>
        <div
          ref={chartContainerRef}
          style={{
            flex: isFullscreen ? 0 : 1,
            marginLeft: isFullscreen ? '550px' : '', // ระยะห่างจากกราฟหลักเมื่ออยู่ในโหมด fullscreen
            width: isFullscreen ? '1700px' : '630px', // กราฟขยายเต็มจอในโหมด Fullscreen
            height: isFullscreen ? '60vh' : '290px', // ความสูงของกราฟในโหมด Fullscreen
            marginTop: isFullscreen ? '50px' : '0', // เว้นระยะในโหมด Fullscreen
            transition: 'all 0.3s ease', // การเปลี่ยนแปลงแบบนุ่มนวล
          }}
        ></div>


        <div style={{
          flex: isFullscreen ? 0 : 0.8,
          padding: isFullscreen ? '10px' : '0px',
          width: isFullscreen ? '500px' : '0px',
          height: isFullscreen ? '500px' : '0px',
          marginTop: isFullscreen ? '100px' : '0',
          marginLeft: isFullscreen ? '600px' : '1px',

        }}>{renderTables()}</div>
      </div>
    </div>
  );

};

export default ChartSectionWithMultipleLines;
